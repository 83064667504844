import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userID: '',
    userName: '',
    permissions: '',
    returnPage: '',
    weChatLogin: false,
  },
  getters: {
    getUserID(state) {
      return state.userID || localStorage.getItem('userID');
    },
    getUserName(state) {
      return state.userName || localStorage.getItem('loginUserName');
    },
    getPermissions(state) {
      return state.permissions || localStorage.getItem('permissions');
    },
    getReturnPage(state) {
      return state.returnPage || localStorage.getItem('returnPage');
    },
    getWeChatLogin(state) {
      return state.weChatLogin || localStorage.getItem('weChatLogin');
    },
  },
  mutations: {
    setUserID(state, val) {
      state.userID = val;
      localStorage.setItem('userID', val);
    },
    setUserName(state, val) {
      state.userName = val;
      localStorage.setItem('loginUserName', val);
    },
    setPermissions(state, val) {
      state.permissions = val;
      localStorage.setItem('permissions', val);
    },
    setReturnPage(state, val) {
      state.returnPage = val;
      localStorage.setItem('returnPage', val);
    },
    setWeChatLogin(state, val) {
      state.weChatLogin = val;
      localStorage.setItem('weChatLogin', val);
    },
  },
  actions: {
  },
  modules: {
  },
});
