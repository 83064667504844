import '@mdi/font/css/materialdesignicons.css';
import '@fontsource/roboto';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        mainColor: '#1f4e79',
        fontColor: '#f1f8ff',
        error: '#E53935',
        markColor: '#e04747',
      },
    },
  },
});
