export default {
  'zh-cn': {
    serviceTerms: '云端平台服务资料搜集及服务条款',
    attachmentLink: '附件连结',
    clauseDescription: `
<p class="mt-2">新代科技股份有限公司(以下简称「<span class="font-weight-black">本公司</span>」)非常重视机敏资料的保护，为维护 贵公司及贵公司之终端客户(以下简称「<span  class="font-weight-black">终端客户</span>」)购买或使用本公司产品：【SynMachine产品】之权益，并提供贵公司【客户管理云端平台】(以下简称「<span  class="font-weight-black">本云端平台</span>」)之服务，特针对机敏数据之搜集及使用订定规范如下(以下简称「<span class="font-weight-black">本服务条款</span>」)，贵我双方同意配合遵守之：</p>
<p>1.为提供本云端平台信息维护服务， 贵公司同意本公司得向其搜集特定机敏资料(定义如后)，本公司并同意针对前述机敏资料将仅作管理终端客户、统计调查与分析、信息与数据库管理之目的使用。</p>
<p>2.本公司搜集之资料仅限于下述类别(以下简称「<span class="font-weight-black">机敏数据</span>」)，其中涉及终端客户及其联络人相关之资料， 贵公司同意与本公司共同搜集。除贵我双方另有约定外， 贵公司同意不会将机敏数据以外之其他数据储存或利用于本云端平台：</p>
<p class="text-indent"><span>· 终端客户资料(限于宝号、公司名称、公司国别、公司地址、公司传真号码、联络人姓名及联络电话及联络人电子信箱)；</span></p>
<p class="text-indent"><span>· 购买及设备信息(贵公司名称、销货日期、机械型号、机械序号、机台产业、维修数据、还原数据)；及</span></p>
<p class="text-indent"><span>· 贵公司自行上传本云端平台之机台还原档案(参数配置文件)。</span></p>
<p>3.贵公司应就上述资料中涉及终端客户个人资料之内容，向终端客户联络人进行个人资料搜集、处理及利用之告知(详参附件)。 贵公司如另行向终端客户或其联络人搜集前条以外之资料，应另行标注并向终端客户及其联络人声明该等搜集均与本公司无涉，本公司对搜集前条以外资料概不负任何责任。</p>
<p>4.贵公司人员使用本云端平台应依指示要求保存密码，并不得将密码交由非职务所需之第三人。贵公司使用本云端平台完毕后，应确实注销平台并依指示更新密码，并严禁下列各项行为：</p>
<p class="text-indent"><span>· 非经授权，将终端客户数据传送给非相关职务之员工或贵公司以外之其他人。</span></p>
<p class="text-indent"><span>· 利用本云端平台上传、复制、下载、转载或传送有关涉及损害、骚扰、攻击、恐吓、侮辱或毁谤他人名誉、猥亵、色情或其他不实的谣言与信息。</span></p>
<p class="text-indent"><span>· 非法进入或使用本公司之主机系统。</span></p>
<p class="text-indent"><span>· 非法或非经授权变更或持有贵公司或本公司其他员工之计算机密码。</span></p>
<p class="text-indent"><span>· 恶意于本云端平台散播计算机病毒。</span></p>
<p class="text-indent"><span>· 未经许可，擅自窜改、毁损、删除、窃取、或盗用本公司主机系统上的数据、信息、档案及软件，或更改服务器之设定。</span></p>
<p class="text-indent"><span>· 利用本云端平台资源从事不法交易或违法行为。</span></p>
<p>5.本公司订有信息安全及机敏数据管理相关政策，并对所属员工均有机敏数据保护训练，以确保贵公司及终端客户之资料经妥适保护。</p>
<p>6.本公司得于本云端平台发布相关信息安全管控指示或要求， 贵公司及所属员工应确实配合遵守。</p>
<p>7.针对本云端平台之资料， 贵公司应仅就本服务条款第1条所述之使用目的范围内进行使用，并负保密责任。贵公司并同意就本云端平台服务之数据搜集及使用，对贵公司所属员工进行完善机敏数据保护训练，如有违反保密义务者，将依法负赔偿等相关法律责任。</p>`,
  },
  'zh-tw': {
    serviceTerms: '雲端平臺服務資料搜集及服務條款',
    attachmentLink: '附件連結',
    clauseDescription: `
<p class="mt-2">新代科技股份有限公司(以下簡稱「<span class="font-weight-black">本公司</span>」)非常重視機敏資料的保護，為維護 貴公司及貴公司之終端客戶(以下簡稱「<span  class="font-weight-black">終端客戶</span>」)購買或使用本公司產品：【SynMachine產品】之權益，並提供貴公司【客戶管理雲端平臺】(以下簡稱「<span  class="font-weight-black">本雲端平臺</span>」)之服務，特針對機敏數據之搜集及使用訂定規範如下(以下簡稱「<span class="font-weight-black">本服務條款</span>」)，貴我雙方同意配合遵守之：</p>
<p>1.為提供本雲端平臺信息維護服務， 貴公司同意本公司得向其搜集特定機敏資料(定義如後)，本公司並同意針對前述機敏資料將僅作管理終端客戶、統計調查與分析、信息與數據庫管理之目的使用。</p>
<p>2.本公司搜集之資料僅限於下述類別(以下簡稱「<span class="font-weight-black">機敏數據</span>」)，其中涉及終端客戶及其聯絡人相關之資料， 貴公司同意與本公司共同搜集。除貴我雙方另有約定外， 貴公司同意不會將機敏數據以外之其他數據儲存或利用於本雲端平臺：</p>
<p class="text-indent"><span>· 終端客戶資料(限於寶號、公司名稱、公司國別、公司地址、公司傳真號碼、聯絡人姓名及聯絡電話及聯絡人電子信箱)；</span></p>
<p class="text-indent"><span>· 購買及設備信息(貴公司名稱、銷貨日期、機械型號、機械序號、機臺產業、維修數據、還原數據)；及</span></p>
<p class="text-indent"><span>· 貴公司自行上傳本雲端平臺之機臺還原檔案(參數配置文件)。</span></p>
<p>3.貴公司應就上述資料中涉及終端客戶個人資料之內容，向終端客戶聯絡人進行個人資料搜集、處理及利用之告知(詳參附件)。 貴公司如另行向終端客戶或其聯絡人搜集前條以外之資料，應另行標註並向終端客戶及其聯絡人聲明該等搜集均與本公司無涉，本公司對搜集前條以外資料概不負任何責任。</p>
<p>4.貴公司人員使用本雲端平臺應依指示要求保存密碼，並不得將密碼交由非職務所需之第三人。貴公司使用本雲端平臺完畢後，應確實註銷平臺並依指示更新密碼，並嚴禁下列各項行為：</p>
<p class="text-indent"><span>· 非經授權，將終端客戶數據傳送給非相關職務之員工或貴公司以外之其他人。</span></p>
<p class="text-indent"><span>· 利用本雲端平臺上傳、復制、下載、轉載或傳送有關涉及損害、騷擾、攻擊、恐嚇、侮辱或毀謗他人名譽、猥褻、色情或其他不實的謠言與信息。</span></p>
<p class="text-indent"><span>· 非法進入或使用本公司之主機系統。</span></p>
<p class="text-indent"><span>· 非法或非經授權變更或持有貴公司或本公司其他員工之計算機密碼。</span></p>
<p class="text-indent"><span>· 惡意於本雲端平臺散播計算機病毒。</span></p>
<p class="text-indent"><span>· 未經許可，擅自竄改、毀損、刪除、竊取、或盜用本公司主機系統上的數據、信息、檔案及軟件，或更改服務器之設定。</span></p>
<p class="text-indent"><span>· 利用本雲端平臺資源從事不法交易或違法行為。</span></p>
<p>5.本公司訂有信息安全及機敏數據管理相關政策，並對所屬員工均有機敏數據保護訓練，以確保貴公司及終端客戶之資料經妥適保護。</p>
<p>6.本公司得於本雲端平臺發布相關信息安全管控指示或要求， 貴公司及所屬員工應確實配合遵守。</p>
<p>7.針對本雲端平臺之資料， 貴公司應僅就本服務條款第1條所述之使用目的範圍內進行使用，並負保密責任。貴公司並同意就本雲端平臺服務之數據搜集及使用，對貴公司所屬員工進行完善機敏數據保護訓練，如有違反保密義務者，將依法負賠償等相關法律責任。</p>`,
  },
  'en-us': {
    serviceTerms: 'Cloud platform service data collection and service terms',
    attachmentLink: 'Attachment link',
    clauseDescription: `
<p class="mt-2"> Sindai Technology Co., LTD. (hereinafter referred to as "<span class="font-weight-black"> The company </span>") attaches great importance to the protection of sensitive data, Purchase or use of our products for the maintenance of your company and your company's end customers (" <span class="font-weight-black"> End customers </span> ") : [SynMachine Products] and provide the services of your [Customer Management Cloud Platform] (hereinafter referred to as "<span class="font-weight-black"> this Cloud platform </span>"), We agree to comply with the following regulations regarding the collection and use of smart data (hereinafter referred to as "<span class="font-weight-black"> these Terms of Service </span>") : </p>
<p>1. In order to provide the information maintenance services of the Cloud Platform, you agree that the Company may collect certain smart information from it (as defined below), and the Company agrees that the smart information will only be used for the purpose of managing end customers, statistical survey and analysis, and information and database management. </p>
<p>2. The data collected by the Company is limited to the following categories (hereinafter referred to as "<span class="font-weight-black"> Smart data </span>"), which relates to the data of end customers and their contacts, which you agree to collect jointly with the Company. Unless we agree otherwise, your company agrees not to store or use any data other than smart data on the Cloud Platform: </p>
<p class="text-indent"><span>· Terminal customer information (limited to the title number, company name, company country, company address, company fax number, contact name and contact telephone number and contact E-mail); </span></p>
<p class="text-indent"><span>· Purchase and equipment information (your company name, date of sale, machine model, machine serial number, machine industry, maintenance data, restoration data); And </span></p>
<p class="text-indent"><span>· Your company upload the machine restoration file of this cloud platform (parameter configuration file). </span></p>
<p>3. The Company shall inform the contact person of the end customer of the collection, processing and use of the personal data of the end customer in the above information (see Annex for details). If you collect other information from end customers or their contacts other than the information in the preceding paragraph, you should separately mark and declare to End customers and their contacts that such collection is not related to the Company, and the Company will not be responsible for the collection of information other than the information in the preceding paragraph. </p>
<p>4. When using the Cloud Platform, your company's personnel shall save the password according to the instructions and requirements, and shall not give the password to a third party who is not necessary for the job. After using the cloud platform, your company should log out of the platform and update the password according to instructions, and the following behaviors are strictly prohibited: </p>
<p class="text-indent"><span>· Unauthorised transfer of end-customer data to an employee in a non-relevant position or to someone other than your company. </span></p>
<p class="text-indent"><span>· Use the Cloud Platform to upload, copy, download, republish or transmit rumors and information involving damage, harassment, assault, intimidation, insult or defamation of others, indecent, pornographic or otherwise untrue. </span></p>
<p class="text-indent"><span>· Illegally accessing or using our host system. </span></p>
<p class="text-indent"><span>· Unlawfully or unauthorised change or possession of your or another employee's computer password. </span></p>
<p class="text-indent"><span>· Malicious computer virus spread on the cloud platform. </span></p>
<p class="text-indent"><span>· Without permission, tamper with, destroy, delete, steal, or misappropriate data, information, files, or software on the Company's host system, or change the Settings of the server. </span></p>
<p class="text-indent"><span>· Use the resources of the Cloud Platform to engage in illegal transactions or illegal activities. </span></p>
<p>5. The Company has a policy on information security and smart data management, and has smart data protection training for its employees to ensure that your company's and end customer's data are properly protected. </p>
<p>6. The Company may issue relevant information security control instructions or requirements on the cloud platform, and the company and its employees shall comply with them. </p>
<p>7. For the information of the Cloud Platform, your company shall use it only for the purpose of use as stated in Article 1 of the Terms of Service and bear the responsibility of confidentiality. Your company also agrees to conduct comprehensive and smart data protection training for your employees regarding the data collection and use of the cloud Platform services. If there is a breach of the confidentiality obligation, you will be liable for compensation and other relevant legal responsibilities according to law. </p>`,
  },
};
