export default {
  'zh-tw': {
    TaipeiCity: '台北市',
    XinbeiCity: '新北市',
    TaoyuanCity: '桃園市',
    TaichungCity: '台中市',
    TainanCity: '台南市',
    KaohsiungCity: '高雄市',
    HsinchuCity: '新竹市',
    ChiayiCity: '嘉義市',
    KeelungCity: '基隆市',
    Yilan: '宜蘭縣',
    Hsinchu: '新竹縣',
    Miaoli: '苗栗縣',
    Changhua: '彰化縣',
    Nantou: '南投縣',
    Yunlin: '雲林縣',
    Chiayi: '嘉義縣',
    Pingtung: '屏東縣',
    Hualien: '花蓮縣',
    Taitung: '台東縣',
    Penghu: '澎湖縣',
  },
  'zh-cn': {
    TaipeiCity: '台北市',
    XinbeiCity: '新北市',
    TaoyuanCity: '桃园市',
    TaichungCity: '台中市',
    TainanCity: '台南市',
    KaohsiungCity: '高雄市',
    HsinchuCity: '新竹市',
    ChiayiCity: '嘉义市',
    KeelungCity: '基隆市',
    Yilan: '宜兰县',
    Hsinchu: '新竹县',
    Miaoli: '苗栗县',
    Changhua: '彰化县',
    Nantou: '南投县',
    Yunlin: '云林县',
    Chiayi: '嘉义县',
    Pingtung: '屏东县',
    Hualien: '花莲县',
    Taitung: '台东县',
    Penghu: '澎湖县',
  },
  'en-us': {
    TaipeiCity: 'TaipeiCity',
    XinbeiCity: 'XinbeiCity',
    TaoyuanCity: 'TaoyuanCity',
    TaichungCity: 'TaichungCity',
    TainanCity: 'TainanCity',
    KaohsiungCity: 'KaohsiungCity',
    HsinchuCity: 'HsinchuCity',
    ChiayiCity: 'ChiayiCity',
    KeelungCity: 'KeelungCity',
    Yilan: 'Yilan',
    Hsinchu: 'Hsinchu',
    Miaoli: 'Miaoli',
    Changhua: 'Changhua',
    Nantou: 'Nantou',
    Yunlin: 'Yunlin',
    Chiayi: 'Chiayi',
    Pingtung: 'Pingtung',
    Hualien: 'Hualien',
    Taitung: 'Taitung',
    Penghu: 'Penghu',
  },
};
