export default {
  'zh-tw': {
    AD: '安道爾',
    AE: '阿聯',
    AF: '阿富汗',
    AG: '安地卡及巴布達',
    AI: '安圭拉',
    AL: '阿爾巴尼亞',
    AM: '亞美尼亞',
    AO: '哥拉',
    AQ: '南極洲',
    AR: '阿根廷',
    AS: '美屬薩摩亞',
    AT: '奧地利',
    AU: '澳大利亞',
    AW: '阿魯巴',
    AX: '奧蘭',
    AZ: '亞塞拜然',
    BA: '波士尼亞與赫塞哥維納',
    BB: '巴貝多',
    BD: '孟加拉國',
    BE: '比利時',
    BF: '布吉納法索',
    BG: '保加利亞',
    BH: '巴林',
    BI: '蒲隆地',
    BJ: '貝南',
    BL: '聖巴泰勒米',
    BM: '百慕達',
    BN: '汶萊',
    BO: '玻利維亞',
    BQ: '荷蘭加勒比區',
    BR: '巴西',
    BS: '巴哈馬',
    BT: '不丹',
    BV: '布韋島',
    BW: '波札那',
    BY: '白俄羅斯',
    BZ: '貝里斯',
    CA: '加拿大',
    CC: '科科斯（基林）群島',
    CD: '民主剛果',
    CF: '中非',
    CG: '剛果',
    CH: '瑞士',
    CI: '象牙海岸',
    CK: '庫克群島',
    CL: '智利',
    CM: '喀麥隆',
    CN: '中國',
    CO: '哥倫比亞',
    CR: '哥斯大黎加',
    CU: '古巴',
    CV: '維德角',
    CW: '庫拉索',
    CX: '聖誕島',
    CY: '賽普勒斯',
    CZ: '捷克',
    DE: '德國',
    DJ: '吉布地',
    DK: '丹麥',
    DM: '多米尼克',
    DO: '多明尼加',
    DZ: '阿爾及利亞',
    EC: '厄瓜多',
    EE: '愛沙尼亞',
    EG: '埃及',
    EH: '西撒哈拉',
    ER: '厄利垂亞',
    ES: '西班牙',
    ET: '衣索比亞',
    FI: '芬蘭',
    FJ: '斐濟',
    FK: '福克蘭群島',
    FM: '密克羅尼西亞聯邦',
    FO: '法羅群島',
    FR: '法國',
    GA: '加彭',
    GB: '英國',
    GD: '格瑞那達',
    GE: '喬治亞',
    GF: '法屬圭亞那',
    GG: '根西',
    GH: '加納',
    GI: '直布羅陀',
    GL: '格陵蘭',
    GM: '甘比亞',
    GN: '幾內亞',
    GP: '瓜德羅普',
    GQ: '赤道幾內亞',
    GR: '希臘',
    GS: '南喬治亞和南桑威奇群島',
    GT: '瓜地馬拉',
    GU: '關島',
    GW: '幾內亞比索',
    GY: '蓋亞那',
    HK: '香港',
    HM: '赫德島和麥克唐納群島',
    HN: '宏都拉斯',
    HR: '克羅埃西亞',
    HT: '海地',
    HU: '匈牙利',
    ID: '印尼',
    IE: '愛爾蘭',
    IL: '以色列',
    IM: '曼島',
    IN: '印度',
    IO: '英屬印度洋領地',
    IQ: '伊拉克',
    IR: '伊朗',
    IS: '冰島',
    IT: '義大利',
    JE: '澤西',
    JM: '牙買加',
    JO: '約旦',
    JP: '日本',
    KE: '肯亞',
    KG: '柬埔寨',
    KI: '吉里巴斯',
    KM: '葛摩',
    KN: '聖克里斯多福及尼維斯',
    KP: '北韓',
    KR: '韓國',
    KW: '科威特',
    KY: '開曼群島',
    KZ: '哈薩克',
    LA: '寮國',
    LB: '黎巴嫩',
    LC: '聖露西亞',
    LI: '列支敦斯登',
    LK: '斯里蘭卡',
    LR: '賴比瑞亞',
    LS: '賴索托',
    LT: '立陶宛',
    LU: '盧森堡',
    LV: '拉脫維亞',
    LY: '利比亞',
    MA: '摩洛哥',
    MC: '摩納哥',
    MD: '摩爾多瓦',
    ME: '蒙特內哥羅',
    MF: '法屬聖馬丁',
    MG: '馬達加斯加',
    MH: '馬紹爾群島',
    MK: '北馬其頓',
    ML: '馬利',
    MM: '緬甸',
    MN: '蒙古國',
    MO: '澳門',
    MP: '北馬里亞納群島',
    MQ: '馬提尼克',
    MR: '茅利塔尼亞',
    MS: '蒙特塞拉特',
    MT: '馬爾他',
    MU: '模里西斯',
    MV: '馬爾地夫',
    MW: '馬拉威',
    MX: '墨西哥',
    MY: '馬來西亞',
    MZ: '莫三比克',
    NA: '納米比亞',
    NC: '新喀里多尼亞',
    NE: '尼日',
    NF: '諾福克島',
    NG: '奈及利亞',
    NI: '尼加拉瓜',
    NL: '荷蘭',
    NO: '挪威',
    NP: '尼泊爾',
    NR: '諾魯',
    NU: '紐埃',
    NZ: '紐西蘭',
    OM: '阿曼',
    PA: '巴拿馬',
    PE: '秘魯',
    PF: '法屬玻里尼西亞',
    PG: '巴布亞紐幾內亞',
    PH: '菲律賓',
    PK: '巴基斯坦',
    PL: '波蘭',
    PM: '聖皮埃爾和密克隆',
    PN: '皮特肯群島',
    PR: '波多黎各',
    PS: '巴勒斯坦',
    PT: '葡萄牙',
    PW: '帛琉',
    PY: '巴拉圭',
    QA: '卡達',
    RE: '留尼旺',
    RO: '羅馬尼亞',
    RS: '塞爾維亞',
    RU: '俄羅斯',
    RW: '盧安達',
    SA: '沙烏地阿拉伯',
    SB: '索羅門群島',
    SC: '塞席爾',
    SD: '蘇丹',
    SE: '瑞典',
    SG: '新加坡',
    SH: '聖赫倫那、阿森松和特里斯坦-達庫尼亞',
    SI: '斯洛維尼亞',
    SJ: '斯瓦爾巴和揚馬延',
    SK: '斯洛伐克',
    SL: '獅子山',
    SM: '聖馬利諾',
    SN: '塞內加爾',
    SO: '索馬利亞',
    SR: '蘇利南',
    SS: '南蘇丹',
    ST: '聖多美和普林西比',
    SV: '薩爾瓦多',
    SX: '聖馬丁',
    SY: '敘利亞',
    SZ: '史瓦帝尼',
    TC: '特克斯與凱科斯群島',
    TD: '查德',
    TF: '法屬南方和南極洲領地',
    TG: '多哥',
    TH: '泰國',
    TJ: '塔吉克',
    TK: '托克勞',
    TL: '東帝汶',
    TM: '土庫曼',
    TN: '突尼西亞',
    TO: '東加',
    TR: '土耳其',
    TT: '千里達及托巴哥',
    TV: '吐瓦魯',
    TW: '臺灣',
    TZ: '坦尚尼亞',
    UA: '烏克蘭',
    UG: '烏干達',
    UM: '美國本土外小島嶼',
    US: '美國',
    UY: '烏拉圭',
    UZ: '烏茲別克',
    VA: '梵蒂岡',
    VC: '聖文森及格瑞那丁',
    VE: '委內瑞拉',
    VG: '英屬維京群島',
    VI: '美屬維京群島',
    VN: '越南',
    VU: '萬那杜',
    WF: '瓦利斯和富圖納',
    WS: '薩摩亞',
    YE: '葉門',
    YT: '馬約特',
    ZA: '南非',
    ZM: '尚比亞',
    ZW: '辛巴威',
  },
  'zh-cn': {
    TW: '台湾',
    AD: '安道尔',
    AE: '阿联',
    AF: '阿富汗',
    AG: '安地卡及巴布达',
    AI: '安圭拉',
    AL: '阿尔巴尼亚',
    AM: '亚美尼亚',
    AO: '安哥拉',
    AQ: '南极洲',
    AR: '阿根廷',
    AS: '美属萨摩亚',
    AT: '奥地利',
    AU: '澳大利亚',
    AW: '阿鲁巴',
    AX: '奥兰',
    AZ: '亚塞拜然',
    BA: '波士尼亚与赫塞哥维纳',
    BB: '巴贝多',
    BD: '孟加拉国',
    BE: '比利时',
    BF: '布吉纳法索',
    BG: '保加利亚',
    BH: '巴林',
    BI: '蒲隆地',
    BJ: '贝南',
    BL: '圣巴泰勒米',
    BM: '百慕达',
    BN: '汶莱',
    BO: '玻利维亚',
    BQ: '荷兰加勒比区',
    BR: '巴西',
    BS: '巴哈马',
    BT: '不丹',
    BV: '布韦岛',
    BW: '波札那',
    BY: '白俄罗斯',
    BZ: '贝里斯',
    CA: '加拿大',
    CC: '科科斯（基林）群岛',
    CD: '民主刚果',
    CF: '中非',
    CG: '刚果',
    CH: '瑞士',
    CI: '象牙海岸',
    CK: '库克群岛',
    CL: '智利',
    CM: '喀麦隆',
    CN: '中国',
    CO: '哥伦比亚',
    CR: '哥斯大黎加',
    CU: '古巴',
    CV: '维德角',
    CW: '库拉索',
    CX: '圣诞岛',
    CY: '赛普勒斯',
    CZ: '捷克',
    DE: '德国',
    DJ: '吉布地',
    DK: '丹麦',
    DM: '多米尼克',
    DO: '多明尼加',
    DZ: '阿尔及利亚',
    EC: '厄瓜多',
    EE: '爱沙尼亚',
    EG: '埃及',
    EH: '西撒哈拉',
    ER: '厄利垂亚',
    ES: '西班牙',
    ET: '衣索比亚',
    FI: '芬兰',
    FJ: '斐济',
    FK: '福克兰群岛',
    FM: '密克罗尼西亚联邦',
    FO: '法罗群岛',
    FR: '法国',
    GA: '加彭',
    GB: '英国',
    GD: '格瑞那达',
    GE: '乔治亚',
    GF: '法属圭亚那',
    GG: '根西',
    GH: '加纳',
    GI: '直布罗陀',
    GL: '格陵兰',
    GM: '甘比亚',
    GN: '几内亚',
    GP: '瓜德罗普',
    GQ: '赤道几内亚',
    GR: '希腊',
    GS: '南乔治亚和南桑威奇群岛',
    GT: 'G瓜地马拉',
    GU: '关岛',
    GW: '几内亚比索',
    GY: '盖亚那',
    HK: '香港',
    HM: '赫德岛和麦克唐纳群岛',
    HN: '宏都拉斯',
    HR: '克罗埃西亚',
    HT: '海地',
    HU: '匈牙利',
    ID: '印尼',
    IE: '爱尔兰',
    IL: '以色列',
    IM: '曼岛',
    IN: '印度',
    IO: '英属印度洋领地',
    IQ: '伊拉克',
    IR: '伊朗',
    IS: '冰岛',
    IT: '义大利',
    JE: '泽西',
    JM: '牙买加',
    JO: '约旦',
    JP: '日本',
    KE: '肯亚',
    KG: '吉尔吉斯',
    KH: '柬埔寨',
    KI: '吉里巴斯',
    KM: '葛摩',
    KN: '圣克里斯多福及尼维斯',
    KP: '北韩',
    KR: '韩国',
    KW: '科威特',
    KY: '开曼群岛',
    KZ: '哈萨克',
    LA: '寮国',
    LB: '黎巴嫩',
    LC: '圣露西亚',
    LI: '列支敦斯登',
    LK: '斯里兰卡',
    LR: '赖比瑞亚',
    LS: '赖索托',
    LT: '立陶宛',
    LU: '卢森堡',
    LV: '拉脱维亚',
    LY: '利比亚',
    MA: '摩洛哥',
    MC: '摩纳哥',
    MD: '摩尔多瓦',
    ME: '蒙特内哥罗',
    MF: '法属圣马丁',
    MG: '马达加斯加',
    MH: '马绍尔群岛',
    MK: '北马其顿',
    ML: '马利',
    MM: '缅甸',
    MN: '蒙古国',
    MO: '澳门',
    MP: '北马里亚纳群岛',
    MQ: '马提尼克',
    MR: '茅利塔尼亚',
    MS: '蒙特塞拉特',
    MT: '马尔他',
    MU: '模里西斯',
    MV: '马尔地夫',
    MW: '马拉威',
    MX: '墨西哥',
    MY: '马来西亚',
    MZ: '莫三比克',
    NA: '纳米比亚',
    NC: '新喀里多尼亚',
    NE: '尼日',
    NF: '诺福克岛',
    NG: '奈及利亚',
    NI: '尼加拉瓜',
    NL: '荷兰',
    NO: '挪威',
    NP: '尼泊尔',
    NR: '诺鲁',
    NU: '纽埃',
    NZ: '纽西兰',
    OM: '阿曼',
    PA: '巴拿马',
    PE: '秘鲁',
    PF: '法属玻里尼西亚',
    PG: '巴布亚纽几内亚',
    PH: '菲律宾',
    PK: '巴基斯坦',
    PL: '波兰',
    PM: '圣皮埃尔和密克隆',
    PN: '皮特肯群岛',
    PR: '波多黎各',
    PS: '巴勒斯坦',
    PT: '葡萄牙',
    PW: '帛琉',
    PY: '巴拉圭',
    QA: '卡达',
    RE: '留尼旺',
    RO: '罗马尼亚',
    RS: '塞尔维亚',
    RU: '俄罗斯',
    RW: '卢安达',
    SA: '沙乌地阿拉伯',
    SB: '索罗门群岛',
    SC: '塞席尔',
    SD: '苏丹',
    SE: '瑞典',
    SG: '新加坡',
    SH: '圣赫伦那、阿森松和特里斯坦-达库尼亚',
    SI: '斯洛维尼亚',
    SJ: '斯瓦尔巴和扬马延',
    SK: '斯洛伐克',
    SL: '狮子山',
    SM: '圣马利诺',
    SN: '塞内加尔',
    SO: '索马利亚',
    SR: '苏利南',
    SS: '南苏丹',
    ST: '圣多美和普林西比',
    SV: '萨尔瓦多',
    SX: '圣马丁',
    SY: '叙利亚',
    SZ: '史瓦帝尼',
    TC: '特克斯与凯科斯群岛',
    TD: '查德',
    TF: '法属南方和南极洲领地',
    TG: '多哥',
    TH: '泰国',
    TJ: '塔吉克',
    TK: '托克劳',
    TL: '东帝汶',
    TM: '土库曼',
    TN: '突尼西亚',
    TO: '东加',
    TR: '土耳其',
    TT: '千里达及托巴哥',
    TV: '吐瓦鲁',
    // TW: '台湾',
    TZ: '坦尚尼亚',
    UA: '乌克兰',
    UG: '乌干达',
    UM: '美国本土外小岛屿',
    US: '美国',
    UY: '乌拉圭',
    UZ: '乌兹别克',
    VA: '梵蒂冈',
    VC: '圣文森及格瑞那丁',
    VE: '委内瑞拉',
    VG: '英属维京群岛',
    VI: '美属维京群岛',
    VN: '越南',
    VU: '万那杜',
    WF: '瓦利斯和富图纳',
    WS: '萨摩亚',
    YE: '叶门',
    YT: '马约特',
    ZA: '南非',
    ZM: '尚比亚',
    ZW: '辛巴威',
  },
  'en-us': {
    AD: 'Andorra',
    AE: 'United Arab Emirates',
    AF: 'Afghanistan',
    AG: 'Antigua and Barbuda',
    AI: 'Anguilla',
    AL: 'Albania',
    AM: 'Armenia',
    AO: 'Angola',
    AQ: 'Antarctica',
    AR: 'Argentina',
    AS: 'American Samoa',
    AT: 'Austria',
    AU: 'Australia',
    AW: 'Aruba',
    AX: 'Aland Islands',
    AZ: 'Azerbaijan',
    BA: 'Bosnia and Herzegovina',
    BB: 'Barbados',
    BD: 'Bangladesh',
    BE: 'Belgium',
    BF: 'Burkina Faso',
    BG: 'Bulgaria',
    BH: 'Bahrain',
    BI: 'Burundi',
    BJ: 'Benin',
    BL: 'Saint Barthelemy',
    BM: 'Bermuda',
    BN: 'Brunei Darussalam',
    BO: 'Bolivia Plurinational State of',
    BQ: 'Bonaire Sint Eustatius and Saba',
    BR: 'Brazil',
    BS: 'Bahamas',
    BT: 'Bhutan',
    BV: 'Bouvet Island',
    BW: 'Botswana',
    BY: 'Belarus',
    BZ: 'Belize',
    CA: 'Canada',
    CC: 'Cocos (Keeling) Islands',
    CD: 'Congo the Democratic Republic of the',
    CF: 'Central African Republic',
    CG: 'Congo',
    CH: 'Switzerland',
    CI: 'Cote d‘Ivoire',
    CK: 'Cook Islands',
    CL: 'Chile',
    CM: 'Cameroon',
    CN: 'China',
    CO: 'Colombia',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CV: 'Cabo Verde',
    CW: 'Curacao',
    CX: 'Christmas Island',
    CY: 'Cyprus',
    CZ: 'Czechia',
    DE: 'Germany',
    DJ: 'Djibouti',
    DK: 'Denmark',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    DZ: 'Algeria',
    EC: 'Ecuador',
    EE: 'Estonia',
    EG: 'Egypt',
    EH: 'Western Sahara',
    ER: 'Eritrea',
    ES: 'Spain',
    ET: 'Ethiopia',
    FI: 'Finland',
    FJ: 'Fiji',
    FK: 'Falkland Islands (Malvinas)',
    FM: 'Micronesia Federated States of',
    FO: 'Faroe Islands',
    FR: 'France',
    GA: 'Gabon',
    GB: 'United Kingdom of Great Britain and Northern Ireland',
    GD: 'Grenada',
    GE: 'Georgia',
    GF: 'French Guiana',
    GG: 'Guernsey',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GL: 'Greenland',
    GM: 'Gambia',
    GN: 'Guinea',
    GP: 'Guadeloupe',
    GQ: 'Equatorial Guinea',
    GR: 'Greece',
    GS: 'South Georgia and the South Sandwich Islands',
    GT: 'Guatemala',
    GU: 'Guam',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HK: 'Hong Kong',
    HM: 'Heard Island and McDonald Islands',
    HN: 'Honduras',
    HR: 'Croatia',
    HT: 'Haiti',
    HU: 'Hungary',
    ID: 'Indonesia',
    IE: 'Ireland',
    IL: 'Israel',
    IM: 'Isle of Man',
    IN: 'India',
    IO: 'British Indian Ocean Territory',
    IQ: 'Iraq',
    IR: 'Iran Islamic Republic of',
    IS: 'Iceland',
    IT: 'Italy',
    JE: 'Jersey',
    JM: 'Jamaica',
    JO: 'Jordan',
    JP: 'Japan',
    KE: 'Kenya',
    KG: 'Kyrgyzstan',
    KH: 'Cambodia',
    KI: 'Kiribati',
    KM: 'Comoros',
    KN: 'Saint Kitts and Nevis',
    KP: 'Korea Democratic People‘s Republic of',
    KR: 'Korea Republic of',
    KW: 'Kuwait',
    KY: 'Cayman Islands',
    KZ: 'Kazakhstan',
    LA: 'Lao People‘s Democratic Republic',
    LB: 'Lebanon',
    LC: 'Saint Lucia',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Liberia',
    LS: 'Lesotho',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    LV: 'Latvia',
    LY: 'Libya',
    MA: 'Morocco',
    MC: 'Monaco',
    MD: 'Moldova Republic of',
    ME: 'Montenegro',
    MF: 'Saint Martin (French part)',
    MG: 'Madagascar',
    MH: 'Marshall Islands',
    MK: 'North Macedonia',
    ML: 'Mali',
    MM: 'Myanmar',
    MN: 'Mongolia',
    MO: 'Macao',
    MP: 'Northern Mariana Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Mauritius',
    MV: 'Maldives',
    MW: 'Malawi',
    MX: 'Mexico',
    MY: 'Malaysia',
    MZ: 'Mozambique',
    NA: 'Namibia',
    NC: 'New Caledonia',
    NE: 'Niger',
    NF: 'Norfolk Island',
    NG: 'Nigeria',
    NI: 'Nicaragua',
    NL: 'Netherlands',
    NO: 'Norway',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'New Zealand',
    OM: 'Oman',
    PA: 'Panama',
    PE: 'Peru',
    PF: 'French Polynesia',
    PG: 'Papua New Guinea',
    PH: 'Philippines',
    PK: 'Pakistan',
    PL: 'Poland',
    PM: 'Saint Pierre and Miquelon',
    PN: 'Pitcairn',
    PR: 'Puerto Rico',
    PS: 'Palestine State of',
    PT: 'Portugal',
    PW: 'Palau',
    PY: 'Paraguay',
    QA: 'Qatar',
    RE: 'Reunion',
    RO: 'Romania',
    RS: 'Serbia',
    RU: 'Russian Federation',
    RW: 'Rwanda',
    SA: 'Saudi Arabia',
    SB: 'Solomon Islands',
    SC: 'Seychelles',
    SD: 'Sudan',
    SE: 'Sweden',
    SG: 'Singapore',
    SH: 'Saint Helena Ascension and Tristan da Cunha',
    SI: 'Slovenia',
    SJ: 'Svalbard and Jan Mayen',
    SK: 'Slovakia',
    SL: 'Sierra Leone',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somalia',
    SR: 'Suriname',
    SS: 'South Sudan',
    ST: 'Sao Tome and Principe',
    SV: 'El Salvador',
    SX: 'Sint Maarten (Dutch part)',
    SY: 'Syrian Arab Republic',
    SZ: 'Eswatini',
    TC: 'Turks and Caicos Islands',
    TD: 'Chad',
    TF: 'French Southern Territories',
    TG: 'Togo',
    TH: 'Thailand',
    TJ: 'Tajikistan',
    TK: 'Tokelau',
    TL: 'Timor-Leste',
    TM: 'Turkmenistan',
    TN: 'Tunisia',
    TO: 'Tonga',
    TR: 'Turkey',
    TT: 'Trinidad and Tobago',
    TV: 'Tuvalu',
    TW: 'Taiwan',
    TZ: 'Tanzania  United Republic of',
    UA: 'Ukraine',
    UG: 'Uganda',
    UM: 'United States Minor Outlying Islands',
    US: 'United States of America',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VA: 'Holy See',
    VC: 'Saint Vincent and the Grenadines',
    VE: 'Venezuela  Bolivarian Republic of',
    VG: 'Virgin Islands  British',
    VI: 'Virgin Islands  U.S.',
    VN: 'Viet Nam',
    VU: 'Vanuatu',
    WF: 'Wallis and Futuna',
    WS: 'Samoa',
    YE: 'Yemen',
    YT: 'Mayotte',
    ZA: 'South Africa',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
  },
};
