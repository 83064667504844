import Vue from 'vue';
import * as echarts from 'echarts';
import VueGtag from 'vue-gtag';
import VueClipboard from 'vue-clipboard2';
import VueCompositionAPI from '@vue/composition-api';
import i18n from './plugins/i18n';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

const GA_TAG = process.env.VUE_APP_GA_TAG;

Vue.use(VueGtag, {
  config: { id: GA_TAG },
}, router);
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;
Vue.use(VueCompositionAPI);

new Vue({
  router,
  i18n,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
