export default {
  'zh-tw': {
    lathe: '車床',
    milling: '銑床',
    turningMillingCenter: '車銑複合',
    grinding: '磨床',
    roboticArm: '機械手臂',
    automaticProduct: '自動化產品',
    laserProduct: '雷射產品',
    otherSpecialEquipment: '其他特殊專用設備',
  },
  'zh-cn': {
    lathe: '车床',
    milling: '铣床',
    turningMillingCenter: '车铣複合',
    grinding: '磨床',
    roboticArm: '机械手臂',
    automaticProduct: '自动化产品',
    laserProduct: '雷射产品',
    otherSpecialEquipment: '其他特殊专用设备',
  },
  'en-us': {
    lathe: 'lathe',
    milling: 'milling machine',
    turningMillingCenter: 'complex machine',
    grinding: 'grinding',
    roboticArm: 'robotic arm',
    automaticProduct: 'Automatic product',
    laserProduct: 'Laser product ',
    otherSpecialEquipment: 'Other special equipment',
  },
};
