export default {
  'zh-tw': {
    hardware: '五金',
    woodWork: '木工',
    stoneIndustry: '石材',
    glassIndustry: '玻璃',
    PCB: 'PCB',
    dispensing: '點/塗膠',
    toolGrinding: '刀具研磨',
    mold: '模具',
    buhrimill: '石磨',
    pickPlace: '取放',
    sheetMetal: '鈑金',
    springIndustry: '彈簧',
    otherIndustry: '其他',
  },
  'zh-cn': {
    hardware: '五金',
    woodWork: '木工',
    stoneIndustry: '石材',
    glassIndustry: '玻璃',
    PCB: 'PCB',
    dispensing: '点/涂胶',
    toolGrinding: '刀具研磨',
    mold: '模具',
    buhrimill: '石磨',
    pickPlace: '取放',
    sheetMetal: '钣金',
    springIndustry: '弹簧',
    otherIndustry: '其他',
  },
  'en-us': {
    hardware: 'Hardware',
    woodWork: 'Woodworking',
    stoneIndustry: 'Stone',
    glassIndustry: 'Glass',
    PCB: 'PCB',
    dispensing: 'Point/gluing',
    toolGrinding: 'Tool grinding',
    mold: 'mold',
    buhrimill: 'Stone mill',
    pickPlace: 'pich and place',
    sheetMetal: 'Sheet medal',
    springIndustry: 'Spring',
    otherIndustry: 'Other',
  },
};
