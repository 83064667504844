<template>
  <v-app>
    <router-link to="/"></router-link>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: 'App',
  created() {
  },
};
</script>

<style lang="scss">
  @import '@/scss/base/b-global.scss';
</style>
