export default {
  'zh-tw': {
    System_Error: '系統錯誤',
    Service_Unavailable: '系統服務暫停',
    Remote_Service_Error: '遠端連線錯誤',
    IP_Limit: 'IP限制不能請求該資源',
    Illegal_Request: '非法請求',
    Token_Missing: '缺少source (token) 參數',
    Unsupport_Mediatype: '不支援的MediaType',
    Param_Error: '參數錯誤，您回傳的資料內容或代碼數量不符規範',
    System_Busy: '系統過載',
    Job_Expired: '執行超時',
    RPC_Error: 'RPC錯誤',
    Partial_Data_Error: '部分資料錯誤',
    Time_Out: '連線逾時',
    Mail_Failed: '寄信功能異常',
    Decode_Failed: '解密功能異常',
    WeChat_GetOpenID_Error: '微信取OpenID功能異常',
    SDK_Unsupport_Function: 'SDK不支援此功能',
    API_Internet_Error: '連線其他API失敗',
    SMS_Failed: '簡訊發送異常，請檢查國碼及手機號碼是否輸入正確',
    Insert_Problem_Type_Wrong: '插入錯誤之參數型態不正確',
    Insert_Problem_KeyColumn_Exist: '已存在相同主鍵欄位',
    Update_Problem_Type_Wrong: '更新錯誤之參數型態不正確',
    Update_Problem_KeyColumn_Miss: '缺少主鍵',
    Select_Problem_No_Data: '查無資料',
    Update_Error: '更新錯誤',
    Insert_Error: '插入失敗',
    Upsert_Error: '更新插入失敗',
    Delete_Error: '刪除失敗',
    DBRelated_Error: '資料庫相關系統錯誤',
    Password_Wrong: '密碼錯誤',
    No_Right_to_Access: '權限不足',
    UserID_UnExist: '帳號不存在',
    Account_Quit: '帳號停用',
    Verify_Fail: '驗證錯誤',
    ID_SameAs_Password: '帳號等同於密碼',
    Auth_Error: '帳號認證錯誤',
    Auth_Errors: '帳號認證錯誤',
    Machine_Number_Limit: '機台授權數量限制',
    Token_Expired: '權證時效過期，請重新整理或重新提交表單',
    Password_Need_Change: '密碼必須更改(忘記密碼時)',
    Cookie_Error: 'Cookie驗證錯誤，您的登入時限可能過期，請重新登入',
    WeChat_First_Login: '微信首次登入',
    Token_Verify_Fail: 'Token驗證失敗',
    Option_Right_Invalid: '選配功能權限不足',
    No_Right_to_Access_CNC: '對控制器權限不足',
    Mail_Verify_Fail: '信箱尚未驗證',
    No_Right_to_Access_Equipment: '對其他設備權限不足',
    Equipment_Name_Duplicate: '其他設備名稱重複',
    Software_Serial_Number_Valid: '軟體序號非法，直聯網用之序號不得拿來使用SMB軟體序號相關 API',
    Input_Type_Wrong: '參數型態不正確',
    File_Not_Exist: '檔案不存在',
    File_Using_Conflict: '檔案使用衝突',
    File_Not_ExistInRequest: '檔案不存在於參數中',
    File_Upload_Number_Limit: '檔案上傳數量問題',
    Oss_Upload_Fail: '上傳到OSS失敗',
    File_Size_Exceeded: '單一檔案大小超過上限',
    Folder_Size_Exceeded: '單一資料夾檔案大小超過上限',
    File_Extension_Invalid: '非法的副檔名',
    File_Delete_Fail: '刪除檔案失敗',
    File_Related_Error: '檔案相關錯誤',
    File_InUse_Error: '檔案正在使用中',
    Folder_Not_Exist: '資料夾不存在',
    Folder_InUse_Error: '資料夾正在使用中',
    Folder_Error: '通用資料夾錯誤訊息',
    Connect_Broker_Failed: '連線Broker失敗',
    Subscribe_Topic_Failed: '訂閱topic失敗',
    Publish_Topic_Failed: '發布topic失敗',
    Duplicate_Subscribe_Topic: '重複訂閱topic',
    Not_Subscribe_Topic_Yet: '尚未訂閱此Topic',
    Unsubscribe_Topic_Failed: '取消訂閱topic失敗',
    DisConnect_Broker_Failed: '斷線Broker失敗',
    CNC_MQTT_Error: '通用控制器MQTT相關錯誤',
    CNC_Offline: '控制器離線',
    CNC_Busy: '控制器忙碌中',
    CNC_Busy_MQTT: '控制器因為MQTT指令忙碌中',
    SMB_MQTT_Error: '通用 SMB MQTT 相關錯誤',
    SMB_Offline: 'SMB離線',
    SMB_Busy: 'SMB忙碌中',
    SMB_Busy_MQTT: 'SMB 因為MQTT忙碌中',
    Client_Already_Connect: '已經有相同資訊的Client連線中',
    CNC_Error: '控制器通用錯誤碼',
    CNC_Not_Regist: '控制器沒有註冊過',
    CNC_Already_Decode: '該控制器對應的檢查碼已經解密成功了',
    CNC_Already_Bind_SynFactory: '該控制器已經被註冊綁定給其他終端使用者',
    CNC_Name_Duplicate: '終端使用者的控制器名稱重複',
    CNC_Connection_Full: '終端使用者的連線數量已達上限',
    CNC_Not_Binding: '控制器並沒有被任何終端綁定',
    CNC_Disk_Not_Enough: '控制器磁碟未足夠',
    CNC_Dispatch_Type: '控制器派送類型問題(例如：參數不對)',
    CNC_Busy_FileRelated: '控制器忙碌處理檔案相關事宜',
    Robot_CNC_Direct_Dispatch_Deny: '機器人控制器不允許接收派工',
    CNC_Busy_LogBackup: '控制器忙碌處理Log備份相關事宜',
    CNC_Disconnected: '控制器離線中，無法交握',
    CNC_Invalid_BindingType: '控制器綁定類型不適用，部分功能只有直聯網機台能使用',
    CNC_Invalid_Brand: '控制器廠牌不適用，如部分功能只有新代廠牌能使用',
    Decode_Error: '序號或檢查代碼輸入錯誤',
    Decode_Illegal: '此檢查代碼或解密行為異常，請聯繫新代進一步確認',
    Subaccount_Auth_Illegal: '您沒有權限，請洽您的機械廠管理者',
    VerifyCode_Illegal: '檢查代碼異常',
    SN_Not_Found: '資料庫查無此筆序號',
    Decode_DataBase_Action_Error: '解密資料庫操作錯誤，請聯繫新代進一步確認',
    Remote_Decode_Error: '遠距解密失敗',
    CNCType_Model_Not_Found: '查無控制器機型或控制器類別',
    Wechat_Error: '微信錯誤通用錯誤碼',
    SubAccount_Multi_Wechat_Bind: '子帳號存在多組微信綁定',
    MotherAccount_Multi_Wechat_Bind: '母帳號存在多組為信綁定',
    Production_Plan_Error: '生產計劃管理通用錯誤碼',
    Production_Order_Duplicate: '工單重複',
    Order_Duplicate: '訂單重複',
    Serial_Number: '序號不存在',
    Password_Generation_Error: '密碼產生錯誤',
  },
  'zh-cn': {
    System_Error: '系统错误',
    Service_Unavailable: '系统服务暂停',
    Remote_Service_Error: '远端连线错误',
    IP_Limit: 'IP限制不能请求该资源',
    Illegal_Request: '非法请求',
    Token_Missing: '缺少source (token) 参数',
    Unsupport_Mediatype: '不支援的MediaType',
    Param_Error: '参数错误，您回传的资料内容或代码数量不符规范',
    System_Busy: '系统过载',
    Job_Expired: '执行超时',
    RPC_Error: 'RPC错误',
    Partial_Data_Error: '部分资料错误',
    Time_Out: '连线逾时',
    Mail_Failed: '寄信功能异常',
    Decode_Failed: '解密功能异常',
    WeChat_GetOpenID_Error: '微信取OpenID功能异常',
    SDK_Unsupport_Function: 'SDK不支援此功能',
    API_Internet_Error: '连线其他API失败',
    SMS_Failed: '简讯发送异常，请检查国码及手机号码是否输入正确',
    Insert_Problem_Type_Wrong: '插入错误之参数型态不正确',
    Insert_Problem_KeyColumn_Exist: '已存在相同主键栏位',
    Update_Problem_Type_Wrong: '更新错误之参数型态不正确',
    Update_Problem_KeyColumn_Miss: '缺少主键',
    Select_Problem_No_Data: '查无资料',
    Update_Error: '更新错误',
    Insert_Error: '插入失败',
    Upsert_Error: '更新插入失败',
    Delete_Error: '删除失败',
    DBRelated_Error: '资料库相关系统错误',
    Password_Wrong: '密码错误',
    No_Right_to_Access: '权限不足',
    UserID_UnExist: '帐号不存在',
    Account_Quit: '帐号停用',
    Verify_Fail: '验证错误',
    ID_SameAs_Password: '帐号等同于密码',
    Auth_Error: '帐号认证错误',
    Auth_Errors: '帐号认证错误',
    Machine_Number_Limit: '机台授权数量限制',
    Token_Expired: '权证时效过期，请重新整理或重新提交表单',
    Password_Need_Change: '密码必须更改(忘记密码时)',
    Cookie_Error: 'Cookie验证错误，您的登入时限可能过期，请重新登入',
    WeChat_First_Login: '微信首次登入',
    Token_Verify_Fail: 'Token验证失败',
    Option_Right_Invalid: '选配功能权限不足',
    No_Right_to_Access_CNC: '对控制器权限不足',
    Mail_Verify_Fail: '信箱尚未验证',
    No_Right_to_Access_Equipment: '对其他设备权限不足',
    Equipment_Name_Duplicate: '其他设备名称重复',
    Software_Serial_Number_Valid: '软体序号非法，直联网用之序号不得拿来使用SMB软体序号相关 API',
    Input_Type_Wrong: '参数型态不正确',
    File_Not_Exist: '档案不存在',
    File_Using_Conflict: '档案使用冲突',
    File_Not_ExistInRequest: '档案不存在于参数中',
    File_Upload_Number_Limit: '档案上传数量问题',
    Oss_Upload_Fail: '上传到OSS失败',
    File_Size_Exceeded: '单一档案大小超过上限',
    Folder_Size_Exceeded: '单一资料夹档案大小超过上限',
    File_Extension_Invalid: '非法的副档名',
    File_Delete_Fail: '删除档案失败',
    File_Related_Error: '档案相关错误',
    File_InUse_Error: '档案正在使用中',
    Folder_Not_Exist: '资料夹不存在',
    Folder_InUse_Error: '资料夹正在使用中',
    Folder_Error: '通用资料夹错误讯息',
    Connect_Broker_Failed: '连线Broker失败',
    Subscribe_Topic_Failed: '订阅topic失败',
    Publish_Topic_Failed: '发布topic失败',
    Duplicate_Subscribe_Topic: '重复订阅topic',
    Not_Subscribe_Topic_Yet: '尚未订阅此Topic',
    Unsubscribe_Topic_Failed: '取消订阅topic失败',
    DisConnect_Broker_Failed: '断线Broker失败',
    CNC_MQTT_Error: '通用控制器MQTT相关错误',
    CNC_Offline: '控制器离线',
    CNC_Busy: '控制器忙碌中',
    CNC_Busy_MQTT: '控制器因为MQTT指令忙碌中',
    SMB_MQTT_Error: '通用 SMB MQTT 相关错误',
    SMB_Offline: 'SMB离线',
    SMB_Busy: 'SMB忙碌中',
    SMB_Busy_MQTT: 'SMB 因为MQTT忙碌中',
    Client_Already_Connect: '已经有相同资讯的Client连线中',
    CNC_Error: '控制器通用错误码',
    CNC_Not_Regist: '控制器没有注册过',
    CNC_Already_Decode: '该控制器对应的检查码已经解密成功了',
    CNC_Already_Bind_SynFactory: '该控制器已经被注册绑定给其他终端使用者',
    CNC_Name_Duplicate: '终端使用者的控制器名称重复',
    CNC_Connection_Full: '终端使用者的连线数量已达上限',
    CNC_Not_Binding: '控制器并没有被任何终端绑定',
    CNC_Disk_Not_Enough: '控制器磁碟未足够',
    CNC_Dispatch_Type: '控制器派送类型问题(例如：参数不对)',
    CNC_Busy_FileRelated: '控制器忙碌处理档案相关事宜',
    Robot_CNC_Direct_Dispatch_Deny: '机器人控制器不允许接收派工',
    CNC_Busy_LogBackup: '控制器忙碌处理Log备份相关事宜',
    CNC_Disconnected: '控制器离线中，无法交握',
    CNC_Invalid_BindingType: '控制器绑定类型不适用，部分功能只有直联网机台能使用',
    CNC_Invalid_Brand: '控制器厂牌不适用，如部分功能只有新代厂牌能使用',
    Decode_Error: '序号或检查代码输入错误',
    Decode_Illegal: '此检查代码或解密行为异常，请联系新代进一步确认',
    Subaccount_Auth_Illegal: '您没有权限，请洽您的机械厂管理者',
    VerifyCode_Illegal: '检查代码异常',
    SN_Not_Found: '资料库查无此笔序号',
    Decode_DataBase_Action_Error: '解密资料库操作错误，请联系新代进一步确认',
    Remote_Decode_Error: '远距解密失败',
    CNCType_Model_Not_Found: '查无控制器机型或控制器类别',
    Wechat_Error: '微信错误通用错误码',
    SubAccount_Multi_Wechat_Bind: '子帐号存在多组微信绑定',
    MotherAccount_Multi_Wechat_Bind: '母帐号存在多组为信绑定',
    Production_Plan_Error: '生产计划管理通用错误码',
    Production_Order_Duplicate: '工单重复',
    Order_Duplicate: '订单重复',
    Serial_Number: '序号不存在',
    Password_Generation_Error: '密码产生错误',
  },
  'en-us': {
    System_Error: 'System Error.',
    Service_Unavailable: 'System service is suspended.',
    Remote_Service_Error: 'Remote connection error.',
    IP_Limit: 'IP limit cannot request this resource.',
    Illegal_Request: 'Illegal request.',
    Token_Missing: 'The source (token) parameter is missing.',
    Unsupport_Mediatype: 'Unsupported MediaType.',
    Param_Error: 'Parameter error, the content of the data or the number of codes you returned does not meet the specifications.',
    System_Busy: 'System overload.',
    Job_Expired: 'Execution timed out.',
    RPC_Error: 'RPC error.',
    Partial_Data_Error: 'Partial data error.',
    Time_Out: 'Connection timed out.',
    Mail_Failed: 'The mailing function is abnormal.',
    Decode_Failed: 'Decryption function is abnormal.',
    WeChat_GetOpenID_Error: 'WeChat get OpenID function abnormal.',
    SDK_Unsupport_Function: 'SDK unsupport function.',
    API_Internet_Error: 'Failed to connect to other API.',
    SMS_Failed: 'SMS sending is abnormal. Please check whether the country code and phone number are entered correctly.',
    Insert_Problem_Type_Wrong: 'Insert Error. The parameter type is incorrect.',
    Insert_Problem_KeyColumn_Exist: 'The same primary key column already exists.',
    Update_Problem_Type_Wrong: 'Update Error. The parameter type is incorrect.',
    Update_Problem_KeyColumn_Miss: 'Missing primary key.',
    Select_Problem_No_Data: 'Check no data.',
    Update_Error: 'Update error.',
    Insert_Error: 'Insert failed.',
    Upsert_Error: 'Update insert failed.',
    Delete_Error: 'Delete failed.',
    DBRelated_Error: 'DB Related Error.',
    Password_Wrong: 'Password Wrong.',
    No_Right_to_Access: 'Insufficient permissions.',
    UserID_UnExist: 'Account does not exist.',
    Account_Quit: 'Account is disabled.',
    Verify_Fail: 'Verification error.',
    ID_SameAs_Password: 'Account is equivalent to password.',
    Auth_Error: 'Account authentication error.',
    Auth_Errors: 'Account authentication error.',
    Machine_Number_Limit: 'Machine authorized number limit.',
    Token_Expired: 'Login time limit expired, please refresh or resubmit the form.',
    Password_Need_Change: 'The password must be changed (when the password is forgotten).',
    Cookie_Error: 'Cookie verification error. Your login time limit may have expired, please login again.',
    WeChat_First_Login: 'WeChat first login.',
    Token_Verify_Fail: 'Token verification failed.',
    Option_Right_Invalid: 'Insufficient Option right.',
    No_Right_to_Access_CNC: 'No right to access CNC.',
    Mail_Verify_Fail: 'The mail has not been verified.',
    No_Right_to_Access_Equipment: 'Insufficient permission to other devices.',
    Equipment_Name_Duplicate: 'Other equipment names are duplicated.',
    Software_Serial_Number_Valid: 'The software serial number is illegal, the serial number used for direct networking cannot be used to use the SMB software serial number related API.',
    Input_Type_Wrong: 'The parameter type is incorrect.',
    File_Not_Exist: 'File does not exist.',
    File_Using_Conflict: 'File usage conflict.',
    File_Not_ExistInRequest: 'File does not exist in the parameter.',
    File_Upload_Number_Limit: 'File upload number problem.',
    Oss_Upload_Fail: 'Upload to OSS failed.',
    File_Size_Exceeded: 'Single file size exceeds the upper limit.',
    Folder_Size_Exceeded: 'The file size of a single folder exceeds the upper limit.',
    File_Extension_Invalid: 'Illegal extension.',
    File_Delete_Fail: 'Failed to delete file.',
    File_Related_Error: 'File related error.',
    File_InUse_Error: 'File is in use.',
    Folder_Not_Exist: 'Folder does not exist.',
    Folder_InUse_Error: 'Folder is in use.',
    Folder_Error: 'Generic folder error message.',
    Connect_Broker_Failed: 'Failed to connect to Broker.',
    Subscribe_Topic_Failed: 'Subscription to topic failed.',
    Publish_Topic_Failed: 'Publish topic failed.',
    Duplicate_Subscribe_Topic: 'Duplicate subscription topic.',
    Not_Subscribe_Topic_Yet: 'Not yet subscribed to this topic.',
    Unsubscribe_Topic_Failed: 'Unsubscribe topic failed.',
    DisConnect_Broker_Failed: 'Broker failed to disconnect.',
    CNC_MQTT_Error: 'General controller MQTT related error.',
    CNC_Offline: 'Controller is offline.',
    CNC_Busy: 'The controller is busy.',
    CNC_Busy_MQTT: 'The controller is busy due to MQTT instructions.',
    SMB_MQTT_Error: 'General SMB MQTT related errors.',
    SMB_Offline: 'SMB offline.',
    SMB_Busy: 'SMB is busy.',
    SMB_Busy_MQTT: 'SMB is busy because of MQTT.',
    Client_Already_Connect: 'A client with the same information is already connected',
    CNC_Error: 'controller general error code.',
    CNC_Not_Regist: 'The controller has not been registered.',
    CNC_Already_Decode: 'The check code corresponding to this controller has been successfully decrypted.',
    CNC_Already_Bind_SynFactory: 'The controller has been registered and bound to other user.',
    CNC_Name_Duplicate: 'The controller name of the end user is duplicated.',
    CNC_Connection_Full: 'The number of connections for the end user has reached the upper limit.',
    CNC_Not_Binding: 'The controller is not bound by any terminal.',
    CNC_Disk_Not_Enough: 'Not enough CNC disk',
    CNC_Dispatch_Type: 'The CNC dispatch type problem (for example: the parameter is incorrect)',
    CNC_Busy_FileRelated: 'The CNC is busy with file related matters',
    Robot_CNC_Direct_Dispatch_Deny: 'The Robot CNC is not allowed to dispatch',
    CNC_Busy_LogBackup: 'The CNC is processing log backup related matters',
    CNC_Disconnected: 'The CNC is disconnected',
    CNC_Invalid_BindingType: 'The CNC binding type is not applicable. Some functions can only be used by front market machines',
    CNC_Invalid_Brand: 'The CNC brand is not applicable. Some functions can only be used by the Syntec brand.',
    Decode_Error: 'Serial number or check code input error.',
    Decode_Illegal: 'This check code or decryption behavior is abnormal, please contact the new generation for further confirmation.',
    Subaccount_Auth_Illegal: 'You do not have permission, please contact your machine factory manager.',
    VerifyCode_Illegal: 'Check code exception.',
    SN_Not_Found: 'No such serial number found in the database.',
    Decode_DataBase_Action_Error: 'Decode database operation error, please contact New Generation for further confirmation.',
    Remote_Decode_Error: 'Remote decryption failed.',
    CNCType_Model_Not_Found: 'Check without controller model or controller type.',
    Wechat_Error: 'Wechat error.',
    SubAccount_Multi_Wechat_Bind: 'There are multiple WeChat bindings for sub-accounts.',
    MotherAccount_Multi_Wechat_Bind: 'The parent account has multiple groups for WeChat binding.',
    Production_Plan_Error: 'Production plan management error.',
    Production_Order_Duplicate: 'Work order duplicate.',
    Order_Duplicate: 'Order duplicate.',
    Serial_Number: 'Serial number does not exist',
    Password_Generation_Error: 'Password generation error',
  },
};
