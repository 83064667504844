import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import i18n from '../plugins/i18n';
import rightList from '../configFiles/rightList.json';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      redirect: `/${i18n.locale}`,
    },
    {
      path: '/:lang',
      component: {
        render(c) {
          return c('router-view');
        },
      },
      children: [
        {
          path: '/',
          name: 'login',
          component: () => import('../views/login.vue'),
        },
        {
          path: 'distinguishAccounts',
          name: 'distinguishAccounts',
          component: () => import('../views/distinguishAccounts.vue'),
        },
        {
          path: 'terminalRegistrationSuccess',
          name: 'terminalRegistrationSuccess',
          component: () => import('../views/terminalRegistrationSuccess.vue'),
        },
        {
          path: 'register',
          name: 'register',
          component: () => import('../views/register.vue'),
        },
        {
          path: 'editPassword',
          name: 'editPassword',
          component: () => import('../views/editPassword.vue'),
        },
        {
          path: 'forgetPassword',
          name: 'forgetPassword',
          component: () => import('../views/forgetPassword.vue'),
        },
        {
          path: 'resetNewPassword',
          name: 'resetNewPassword',
          component: () => import('../views/resetNewPassword.vue'),
        },
        {
          path: 'errorPage',
          name: 'errorPage',
          component: () => import('../views/errorPage.vue'),
        },
        {
          path: 'decodeInfo',
          name: 'decodeInfo',
          component: () => import('../views/pwdDecryption/decodeInfo.vue'),
        },
        {
          path: 'privacyPolicy',
          name: 'privacyPolicy',
          component: () => import('../views/privacyPolicy/privacyPolicy.vue'),
        },
        {
          path: '/',
          name: 'main',
          component: () => import('../views/main.vue'),
          children: [
            {
              path: 'mainPage',
              name: 'mainPage',
              meta: {
                rights: [],
              },
              component: () => import('../views/mainPage.vue'),
            },
            {
              path: 'decodeDate',
              name: 'decodeDate',
              meta: {
                rights: [rightList.Decode_Date],
              },
              component: () => import('../views/pwdDecryption/decodeDate.vue'),
            },
            {
              path: 'decodeServo',
              name: 'decodeServo',
              meta: {
                rights: [rightList.Decode_Servo, rightList.TwoDecode_Servo],
              },
              component: () => import('../views/pwdDecryption/decodeServo.vue'),
            },
            {
              path: 'decodeHardware',
              name: 'decodeHardware',
              meta: {
                rights: [rightList.Decode_Hardware],
              },
              component: () => import('../views/pwdDecryption/decodeHardware.vue'),
            },
            {
              path: 'decodePwdGenV1',
              name: 'decodePwdGenV1',
              meta: {
                rights: [rightList.Decode_Pwd_Gen_V1],
              },
              component: () => import('../views/pwdDecryption/decodePwdGenV1.vue'),
            },
            {
              path: 'decodeRemote',
              name: 'decodeRemote',
              meta: {
                rights: [rightList.Decode_Pwd_Gen_V2V3],
              },
              component: () => import('../views/pwdDecryption/decodeRemote.vue'),
            },
            {
              path: 'decodePwdGenV2V3',
              name: 'decodePwdGenV2V3',
              meta: {
                rights: [rightList.Decode_Pwd_Gen_V2V3],
              },
              component: () => import('../views/pwdDecryption/decodePwdGenV2V3.vue'),
            },
            {
              path: 'decodePwdGenBatchV2V3',
              name: 'decodePwdGenBatchV2V3',
              meta: {
                rights: [rightList.Decode_Pwd_Gen_V2V3],
              },
              component: () => import('../views/pwdDecryption/decodePwdGenBatchV2V3.vue'),
            },
            {
              path: 'decodeReset',
              name: 'decodeReset',
              meta: {
                rights: [rightList.Decode_Reset],
              },
              component: () => import('../views/pwdDecryption/decodeReset.vue'),
            },
            {
              path: 'decodeRestore',
              name: 'decodeRestore',
              meta: {
                rights: [rightList.Decode_Restore],
              },
              component: () => import('../views/pwdDecryption/decodeRestore.vue'),
            },
            {
              path: 'salesBuy',
              name: 'salesBuy',
              meta: {
                rights: [rightList.Business_Function],
              },
              component: () => import('../views/business/salesBuy.vue'),
            },
            {
              path: 'salesRefund',
              name: 'salesRefund',
              meta: {
                rights: [rightList.Business_Function],
              },
              component: () => import('../views/business/salesRefund.vue'),
            },
            {
              path: 'salesBuySub',
              name: 'salesBuySub',
              meta: {
                rights: [rightList.Sales_Buy_Sub],
              },
              component: () => import('../views/business/salesBuySub.vue'),
            },
            {
              path: 'salesBuyTrial',
              name: 'salesBuyTrial',
              meta: {
                rights: [rightList.Option_Trial_Apply],
              },
              component: () => import('../views/business/salesBuyTrial.vue'),
            },
            {
              path: 'salesBuyIndusMa',
              name: 'salesBuyIndusMa',
              meta: {
                rights: [rightList.Business_Function],
              },
              component: () => import('../views/business/salesBuyIndusMa.vue'),
            },
            {
              path: 'indusTrialMationBuyTrial',
              name: 'indusTrialMationBuyTrial',
              meta: {
                rights: [rightList.Business_Function],
              },
              component: () => import('../views/business/indusTrialMationBuyTrial.vue'),
            },
            {
              path: 'warrantyBuy',
              name: 'warrantyBuy',
              meta: {
                rights: [rightList.Warranty_Buy],
              },
              component: () => import('../views/business/warrantyBuy.vue'),
            },
            {
              path: 'appOption',
              name: 'appOption',
              meta: {
                rights: [rightList.Business_Function],
              },
              component: () => import('../views/business/appOption.vue'),
            },
            {
              path: 'maintainCncWrite',
              name: 'maintainCncWrite',
              meta: {
                rights: [rightList.Maintain_Function],
              },
              component: () => import('../views/maintain/maintainCncWrite.vue'),
            },
            {
              path: 'maintainAllReplace',
              name: 'maintainAllReplace',
              meta: {
                rights: [rightList.Maintain_All_Replace],
              },
              component: () => import('../views/maintain/maintainAllReplace.vue'),
            },
            {
              path: 'maintainTypeChange',
              name: 'maintainTypeChange',
              meta: {
                rights: [rightList.Maintain_Type_Change],
              },
              component: () => import('../views/maintain/maintainTypeChange.vue'),
            },
            {
              path: 'managePersonalData',
              name: 'managePersonalData',
              meta: {
                rights: [],
              },
              component: () => import('../views/management/managePersonalData.vue'),
            },
            {
              path: 'manageFactoryAccount',
              name: 'manageFactoryAccount',
              meta: {
                rights: [rightList.Factory_Account_Manage],
              },
              component: () => import('../views/management/manageFactoryAccount.vue'),
            },
            {
              path: 'manageRecode',
              name: 'manageRecode',
              meta: {
                rights: [rightList.Factory_Account_Manage, rightList.Decode_Query],
              },
              component: () => import('../views/management/manageRecode.vue'),
            },
            {
              path: 'rootManageRole',
              name: 'rootManageRole',
              meta: {
                rights: [rightList.Root_Role_Manage],
              },
              component: () => import('../views/management/rootManageRole.vue'),
            },
            {
              path: 'rootManageRight',
              name: 'rootManageRight',
              meta: {
                rights: [rightList.Root_Right_Manage],
              },
              component: () => import('../views/management/rootManageRight.vue'),
            },
            {
              path: 'setEmpRole',
              name: 'setEmpRole',
              meta: {
                rights: [rightList.Root_Right_Manage],
              },
              component: () => import('../views/management/setEmpRole.vue'),
            },
            {
              path: 'rootManageBuy',
              name: 'rootManageBuy',
              meta: {
                rights: [rightList.Root_Buy_Manage],
              },
              component: () => import('../views/management/rootManageBuy.vue'),
            },
            {
              path: 'diagnosisBackup',
              name: 'diagnosisBackup',
              meta: {
                rights: [rightList.Diagnosis_Backup],
              },
              component: () => import('../views/management/diagnosisBackup.vue'),
            },
            {
              path: 'SNRegistration',
              name: 'SNRegistration',
              meta: {
                rights: [rightList.Product_Registration],
              },
              component: () => import('../views/devicePlatform/SNRegistration.vue'),
            },
            {
              path: 'purchaseWarranty',
              name: 'purchaseWarranty',
              meta: {
                rights: [rightList.Product_Registration],
              },
              component: () => import('../views/devicePlatform/purchaseWarranty.vue'),
            },
            {
              path: 'registSearch',
              name: 'registSearch',
              meta: {
                rights: [rightList.Product_Registration_Query],
              },
              component: () => import('../views/devicePlatform/registSearch.vue'),
            },
            {
              path: 'backupDownload',
              name: 'backupDownload',
              meta: {
                rights: [rightList.Remote_Backup_Download],
              },
              component: () => import('../views/devicePlatform/backupDownload.vue'),
            },
            {
              path: 'cncInfo',
              name: 'cncInfo',
              meta: {
                rights: [rightList.Device_Overview],
              },
              component: () => import('../views/devicePlatform/cncInfo/cncInfo.vue'),
            },
            /*
              待合格地圖申請完成後恢復。
              經2021/07/21雲平台雙週會討論結論，由於7/19蘇州人員使用SynMachine設備分佈時發現，中國地圖沒有包含南沙群島。
              目前SynMachine使用中國地圖並非官方認可，已經Survey百度地圖具備官方認可，但尚無survey如何申請與評估導入成本。
              因此暫時先下架此功能。
              預計於2021/09/01確認此功能是否移除。
            */
            // {
            //   path: 'cncWarHouse',
            //   name: 'cncWarHouse',
            //   meta: {
            //     rights: [rightList.Device_Dashboard],
            //   },
            //   component: () => import('../views/devicePlatform/cncWarHouse.vue'),
            // },
          ],
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  let { lang } = to.params;
  const pageRights = to.meta.rights;
  let haveRight = 0;
  if (!lang) {
    lang = 'zh-cn';
  }
  if (pageRights !== undefined && pageRights.length !== 0) {
    if (store.getters.getPermissions === null) {
      const urlParams = new URLSearchParams(window.location.search);
      const path = window.location.pathname;
      const pathNameIndex = path.lastIndexOf('/') + 1;
      const pathName = path.substr(pathNameIndex);
      store.commit('setReturnPage', `${pathName}?${urlParams}`);
      next(`/${lang}/errorPage`);
      return;
    }
    const rightStr = store.getters.getPermissions.replace(/,/g, ' ');
    const userRight = rightStr.split(' ');
    // 未來若有需求需要把right寫得更細區分頁面權限AND與OR的狀況，或出現需要疊加成類似Role的情形則需要修改(目前寫法為OR)
    pageRights.forEach((pageRight) => {
      if (userRight.includes(pageRight)) {
        haveRight += 1;
      }
    });
    if (haveRight !== 0) {
      next();
    } else {
      next(`/${lang}/errorPage`);
    }
  } else if (pageRights !== undefined && store.getters.getPermissions === null) {
    next(`/${lang}/errorPage`);
  }
  i18n.locale = lang;
  next();
});

export default router;
