export default {
  'zh-tw': {
    Beijing: '北京市',
    TianJin: '天津市',
    Hebei: '河北省',
    Shijiazhuang: '石家莊市',
    Tangshan: '唐山市',
    Qinhuangdao: '秦皇島市',
    Handan: '邯鄲市',
    Xingtai: '邢臺市',
    Baoding: '保定市',
    Zhangjiakou: '張家口市',
    Chengde: '承德市',
    Cangzhou: '滄州市',
    Langfang: '廊坊市',
    Hengshui: '衡水市',
    Shanxi: '山西省',
    Taiyuan: '太原市',
    Datong: '大同市',
    Yangquan: '陽泉市',
    Changzhi: '長治市',
    Jincheng: '晉城市',
    Shuozhou: '朔州市',
    Jinzhong: '晉中市',
    Yuncheng: '運城市',
    Xinzhou: '忻州市',
    Linfen: '臨汾市',
    Luliang: '呂梁市',
    InnerMongoliai: '內蒙古自治區',
    Hohhot: '呼和浩特市',
    Baotou: '包頭市',
    Wuhai: '烏海市',
    Chifeng: '赤峰市',
    Tongliao: '通遼市',
    Erdos: '鄂爾多斯市',
    Hulunbeir: '呼倫貝爾市',
    Bayannaoer: '巴彥淖爾市',
    Wulanchabu: '烏蘭察布市',
    Hinggan: '興安盟',
    Xilingol: '錫林郭勒盟',
    Alxa: '阿拉善盟',
    Liaoning: '遼寧省',
    Shenyang: '瀋陽市',
    Dairen: '大連市',
    Anshan: '鞍山市',
    Fushun: '撫順市',
    Benxi: '本溪市',
    Dandong: '丹東市',
    Jinzhou: '錦州市',
    Yingkou: '營口市',
    Fuxin: '阜新市',
    Liaoyang: '遼陽市',
    Panjin: '盤錦市',
    Tieling: '鐵嶺市',
    Chaoyang: '朝陽市',
    Huludao: '葫蘆島市',
    JilinProvince: '吉林省',
    Changchun: '長春市',
    Jilin: '吉林市',
    Siping: '四平市',
    Liaoyuan: '遼源市',
    Tonghua: '通化市',
    Baishan: '白山市',
    Songyuan: '松原市',
    Baicheng: '白城市',
    YanbianKorean: '延邊朝鮮族自治州',
    Heilongjiang: '黑龍江省',
    Harbin: '哈爾濱市',
    Qiqihar: '齊齊哈爾市',
    Jixi: '雞西市',
    Hegang: '鶴崗市',
    Shuangyashan: '雙鴨山市',
    Daqing: '大慶市',
    Qitaihe: '伊春市',
    Jiamusi: '佳木斯市',
    HeilongjiangQitaihe: '七台河市',
    Mudanjiang: '牡丹江市',
    Heihe: '黑河市',
    Suihua: '綏化市',
    Daxinganling: '大興安嶺地區',
    Shanghai: '上海市',
    Jiangsu: '江蘇省',
    Nanjing: '南京市',
    Wuxi: '無錫市',
    Xuzhou: '徐州市',
    Changzhou: '常州市',
    Suzhou: '蘇州市',
    Nantong: '南通市',
    Lianyungang: '連雲港市',
    Huaian: '淮安市',
    Yancheng: '鹽城市',
    Yangzhou: '揚州市',
    Zhenjiang: '鎮江市',
    JiangsuTaizhou: '泰州市',
    Suqian: '宿遷市',
    Zhejiang: '浙江省',
    Hangzhou: '杭州市',
    Ningbo: '寧波市',
    Wenzhou: '溫州市',
    Jiaxing: '嘉興市',
    Huzhou: '湖州市',
    Shaoxing: '紹興市',
    Jinhua: '金華市',
    Quzhou: '衢州市',
    Zhoushan: '舟山市',
    Taizhou: '台州市',
    Lishui: '麗水市',
    Anhui: '安徽省',
    Hefei: '合肥市',
    Wuhu: '蕪湖市',
    Bengbu: '蚌埠市',
    Huainan: '淮南市',
    Maanshan: '馬鞍山市',
    Huaibei: '淮北市',
    Tongling: '銅陵市',
    Anqing: '安慶市',
    Huangshan: '黃山市',
    Chuzhou: '滁州市',
    Fuyang: '阜陽市',
    AnhuiSuzhou: '宿州市',
    Luan: '六安市',
    Bozhou: '亳州市',
    Chizhou: '池州市',
    Xuancheng: '宣城市',
    Fujian: '福建省',
    Fuzhou: '福州市',
    Xiamen: '廈門市',
    Putian: '莆田市',
    Sanming: '三明市',
    Quanzhou: '泉州市',
    Zhangzhou: '漳州市',
    Nanping: '南平市',
    Longyan: '龍岩市',
    Ningde: '寧德市',
    Jiangxi: '江西省',
    Nanchang: '南昌市',
    Jingdezhen: '景德鎮市',
    Pingxiang: '萍鄉市',
    Jiujiang: '九江市',
    Xinyu: '新餘市',
    Yingtan: '鷹潭市',
    Ganzhou: '贛州市',
    Gian: '吉安市',
    Yichun: '宜春市',
    JiangxiFuzhou: '撫州市',
    Shangrao: '上饒市',
    Shandong: '山東省',
    Jinan: '濟南市',
    Qingdao: '青島市',
    Zibo: '淄博市',
    Zaozhuang: '棗莊市',
    Dongying: '東營市',
    Yantai: '煙臺市',
    Weifang: '濰坊市',
    Jining: '濟寧市',
    Tyan: '泰安市',
    Weihai: '威海市',
    Rizhao: '日照市',
    Linyi: '臨沂市',
    Liaocheng: '聊城市',
    Texas: '德州市',
    Binzhou: '濱州市',
    Heze: '菏澤市',
    Henan: '河南省',
    Zhengzhou: '鄭州市',
    Kaifeng: '開封市',
    Luoyang: '洛陽市',
    Pingdingshan: '平頂山市',
    Anyang: '安陽市',
    Hebi: '鶴壁市',
    Xinxiang: '新鄉市',
    Jiaozuo: '焦作市',
    Puyang: '濮陽市',
    Xuchang: '許昌市',
    Luohe: '漯河市',
    Sanmenxia: '三門峽市',
    Nanyang: '南陽市',
    Shangqiu: '商丘市',
    Xinyang: '信陽市',
    Zhoukou: '周口市',
    Zhumadian: '駐馬店市',
    Hubei: '湖北省',
    Wuhan: '武漢市',
    Huangshi: '黃石市',
    Shiyan: '十堰市',
    Yichang: '宜昌市',
    Xiangyang: '襄陽市',
    Ezhou: '鄂州市',
    Jingmen: '荊門市',
    Xiaogan: '孝感市',
    Jingzhou: '荊州市',
    Huanggang: '黃岡市',
    Xianning: '咸寧市',
    Suizhou: '隨州市',
    EnshiTujiaMiao: '恩施土家族苗族自治州',
    Hunan: '湖南省',
    Changsha: '長沙市',
    Zhuzhou: '株洲市',
    Xiangtan: '湘潭市',
    Hengyang: '衡陽市',
    Shaoyang: '邵陽市',
    Yueyang: '岳陽市',
    Changde: '常德市',
    Zhangjiajie: '張家界市',
    Yiyang: '益陽市',
    Chenzhou: '郴州市',
    Yongzhou: '永州市',
    Huaihua: '懷化市',
    XiangxiTujiaandMiaoAutonomousPrefecture: '湘西土家族苗族',
    Loudi: '婁底市',
    Guangdong: '廣東省',
    Guangzhou: '廣州市',
    Shaoguan: '韶關市',
    Shenzhen: '深圳市',
    Zhuhai: '珠海市',
    Shantou: '汕頭市',
    Foshan: '佛山市',
    Jiangmen: '江門市',
    Zhanjiang: '湛江市',
    Maoming: '茂名市',
    Zhaoqing: '肇慶市',
    Huizhou: '惠州市',
    Meizhou: '梅州市',
    Shanwei: '汕尾市',
    Heyuan: '河源市',
    Yangjiang: '陽江市',
    Qingyuan: '清遠市',
    Dongguan: '東莞市',
    Zhongshan: '中山市',
    Chaozhou: '潮州市',
    Jieyang: '揭陽市',
    Yunfu: '雲浮市',
    Guangxi: '廣西壯族自治區',
    Nanning: '南寧市',
    Liuzhou: '柳州市',
    Guilin: '桂林市',
    Wuzhou: '梧州市',
    Beihai: '北海市',
    Fangchenggang: '防城港市',
    Qinzhou: '欽州市',
    Guigang: '貴港市',
    GuangxiYulin: '玉林市',
    Baise: '百色市',
    Hezhou: '賀州市',
    Hechi: '河池市',
    Laibin: '來賓市',
    Chongzuo: '崇左市',
    Hainan: '海南省',
    Haikou: '海口市',
    Sanya: '三亞市',
    Sansha: '三沙市',
    Danzhou: '儋州市',
    Chongqing: '重慶市',
    Sichuan: '四川省',
    Chengdu: '成都市',
    Zigong: '自貢市',
    Panzhihua: '攀枝花市',
    Deyang: '德陽市',
    Luzhou: '瀘州市',
    Mianyang: '綿陽市',
    Guangyuan: '廣元市',
    Suining: '遂寧市',
    Neijiang: '內江市',
    Leshan: '樂山市',
    Nanchong: '南充市',
    Meishan: '眉山市',
    Yibin: '宜賓市',
    Guangan: '廣安市',
    Dazhou: '達州市',
    Yaan: '雅安市',
    Bazhong: '巴中市',
    Ziyang: '資陽市',
    AbaTibetanandQiangAutonomousPrefecture: '阿壩藏族羌族自治州',
    GanziTibetanAutonomousPrefecture: '甘孜藏族自治州',
    LiangshanYiAutonomousPrefecture: '涼山彝族自治州',
    Guizhou: '貴州省',
    Guiyang: '貴陽市',
    Liupanshui: '六盤水市',
    Zunyi: '遵義市',
    Anshun: '安順市',
    Bijie: '畢節市',
    Tongren: '銅仁市',
    MiaoAutonomousPrefecture: '黔西南布依族苗族自治州',
    SoutheastGuizhouMiaoandDongAutonomousPrefecture: '黔東南苗族侗族自治州',
    WeinanBuyiandMiaoAutonomousPrefecture: '黔南布依族苗族自治州',
    Yunnan: '雲南省',
    Kunming: '昆明市',
    Qujing: '曲靖市',
    Yuxi: '玉溪市',
    Baoshan: '保山市',
    Zhaotong: '昭通市',
    Lincang: '麗江市',
    Puer: '普洱市',
    LingQang: '臨滄市',
    ChuxiongYiAutonomousPrefecture: '楚雄彝族自治州',
    HongheHaniandYiAutonomousPrefecture: '紅河哈尼族彝族自治州',
    WenshanZhuangandMiaoAutonomousPrefecture: '文山壯族苗族自治州',
    XishuangbannaDaiAutonomousPrefecture: '西雙版納傣族自治州',
    DaliBaiAutonomousPrefecture: '大理白族自治州',
    DehongDaiJingpoAutonomousPrefecture: '德宏傣族景頗族自治州',
    NujiangYiAutonomousPrefecture: '怒江傈僳族自治州',
    DiqingTibetanAutonomousPrefecture: '迪慶藏族自治州',
    Tibet: '西藏自治區',
    Lhasa: '拉薩市',
    Shigatse: '日喀則市',
    Changdu: '昌都市',
    Linzhi: '林芝市',
    Shannan: '山南市',
    Nagqu: '那曲市',
    Ali: '阿里地區',
    Shaanxi: '陝西省',
    Xian: '西安市',
    Tongchuan: '銅川市',
    Baoji: '寶雞市',
    Xianyang: '咸陽市',
    Weinan: '渭南市',
    Yanan: '延安市',
    Hanzhong: '漢中市',
    Yulin: '榆林市',
    Ankang: '安康市',
    Shangluo: '商洛市',
    Gansu: '甘肅省',
    Lanzhou: '蘭州市',
    Jiayuguan: '嘉峪關市',
    KimChang: '金昌市',
    Baiyin: '白銀市',
    Tianshui: '天水市',
    Wuwei: '武威市',
    Zhangye: '張掖市',
    Pingliang: '平涼市',
    Jiuquan: '酒泉市',
    Qingyang: '慶陽市',
    Dingxi: '定西市',
    Longnan: '隴南市',
    Linxia: '臨夏回族自治州',
    Gannan: '甘南藏族自治州',
    Qinghai: '青海省',
    Xining: '西寧市',
    Haidong: '海東市',
    Haibei: '海北藏族自治州',
    Huangnan: '黃南藏族自治州',
    HainanTibetanAutonomousPrefecture: '海南藏族自治州',
    Guoluo: '果洛藏族自治州',
    Yushu: '玉樹藏族自治州',
    Haixi: '海西蒙古族藏族自治州',
    Ningxia: '寧夏回族自治區',
    Yinchuan: '銀川市',
    Shizuishan: '石嘴山市',
    WuZhong: '吳忠市',
    Guyuan: '固原市',
    Zhongwei: '中衛市',
    Xinjiang: '新疆維吾爾自治區',
    Urumqi: '烏魯木齊市',
    Karamay: '克拉瑪依市',
    Turpan: '吐魯番市',
    Hami: '哈密市',
    Changji: '昌吉回族自治州',
    BortalaMongolianAutonomousPrefecture: '博爾塔拉蒙古自治州',
    BayinGuoyuMongolianAutonomousPrefecture: '巴音郭楞蒙古自治州',
    Akesu: '阿克蘇地區',
    KyzlerKirkAutonomousPrefecture: '克孜勒蘇柯爾克孜自治州',
    Kashi: '喀什地區',
    Hetian: '和田地區',
    IliKazakhAutonomousPrefecture: '伊犁哈薩克自治州',
    Tachengarea: '塔城地區',
    Altayarea: '阿勒泰地區',
    Hongkong: '香港特別行政區',
    Macao: '澳門特別行政區',
    OtherCity: '其它城市',
    OtherProvince: '其它省份',
  },
  'zh-cn': {
    Beijing: '北京市',
    TianJin: '天津市',
    Hebei: '河北省',
    Shijiazhuang: '石家庄市',
    Tangshan: '唐山市',
    Qinhuangdao: '秦皇岛市',
    Handan: '邯郸市',
    Xingtai: '邢台市',
    Baoding: '保定市',
    Zhangjiakou: '张家口市',
    Chengde: '承德市',
    Cangzhou: '沧州市',
    Langfang: '廊坊市',
    Hengshui: '衡水市',
    Shanxi: '山西省',
    Taiyuan: '太原市',
    Datong: '大同市',
    Yangquan: '阳泉市',
    Changzhi: '长治市',
    Jincheng: '晋城市',
    Shuozhou: '朔州市',
    Jinzhong: '晋中市',
    Yuncheng: '运城市',
    Xinzhou: '忻州市',
    Linfen: '临汾市',
    Luliang: '吕梁市',
    InnerMongoliai: '内蒙古自治区',
    Hohhot: '呼和浩特市',
    Baotou: '包头市',
    Wuhai: '乌海市',
    Chifeng: '赤峰市',
    Tongliao: '通辽市',
    Erdos: '鄂尔多斯市',
    Hulunbeir: '呼伦贝尔市',
    Bayannaoer: '巴彦淖尔市',
    Wulanchabu: '乌兰察布市',
    Hinggan: '兴安盟',
    Xilingol: '锡林郭勒盟',
    Alxa: '阿拉善盟',
    Liaoning: '辽宁省',
    Shenyang: '沈阳市',
    Dairen: '大连市',
    Anshan: '鞍山市',
    Fushun: '抚顺市',
    Benxi: '本溪市',
    Dandong: '丹东市',
    Jinzhou: '锦州市',
    Yingkou: '营口市',
    Fuxin: '阜新市',
    Liaoyang: '辽阳市',
    Panjin: '盘锦市',
    Tieling: '铁岭市',
    Chaoyang: '朝阳市',
    Huludao: '葫芦岛市',
    JilinProvince: '吉林省',
    Changchun: '长春市',
    Jilin: '吉林市',
    Siping: '四平市',
    Liaoyuan: '辽源市',
    Tonghua: '通化市',
    Baishan: '白山市',
    Songyuan: '松原市',
    Baicheng: '白城市',
    YanbianKorean: '延边朝鲜族自治州',
    Heilongjiang: '黑龙江省',
    Harbin: '哈尔滨市',
    Qiqihar: '齐齐哈尔市',
    Jixi: '鸡西市',
    Hegang: '鹤岗市',
    Shuangyashan: '双鸭山市',
    Daqing: '大庆市',
    Qitaihe: '伊春市',
    Jiamusi: '佳木斯市',
    HeilongjiangQitaihe: '七台河市',
    Mudanjiang: '牡丹江市',
    Heihe: '黑河市',
    Suihua: '绥化市',
    Daxinganling: '大兴安岭地区',
    Shanghai: '上海市',
    Jiangsu: '江苏省',
    Nanjing: '南京市',
    Wuxi: '无锡市',
    Xuzhou: '徐州市',
    Changzhou: '常州市',
    Suzhou: '苏州市',
    Nantong: '南通市',
    Lianyungang: '连云港市',
    Huaian: '淮安市',
    Yancheng: '盐城市',
    Yangzhou: '扬州市',
    Zhenjiang: '镇江市',
    JiangsuTaizhou: '泰州市',
    Suqian: '宿迁市',
    Zhejiang: '浙江省',
    Hangzhou: '杭州市',
    Ningbo: '宁波市',
    Wenzhou: '温州市',
    Jiaxing: '嘉兴市',
    Huzhou: '湖州市',
    Shaoxing: '绍兴市',
    Jinhua: '金华市',
    Quzhou: '衢州市',
    Zhoushan: '舟山市',
    Taizhou: '台州市',
    Lishui: '丽水市',
    Anhui: '安徽省',
    Hefei: '合肥市',
    Wuhu: '芜湖市',
    Bengbu: '蚌埠市',
    Huainan: '淮南市',
    Maanshan: '马鞍山市',
    Huaibei: '淮北市',
    Tongling: '铜陵市',
    Anqing: '安庆市',
    Huangshan: '黄山市',
    Chuzhou: '滁州市',
    Fuyang: '阜阳市',
    AnhuiSuzhou: '宿州市',
    Luan: '六安市',
    Bozhou: '亳州市',
    Chizhou: '池州市',
    Xuancheng: '宣城市',
    Fujian: '福建省',
    Fuzhou: '福州市',
    Xiamen: '厦门市',
    Putian: '莆田市',
    Sanming: '三明市',
    Quanzhou: '泉州市',
    Zhangzhou: '漳州市',
    Nanping: '南平市',
    Longyan: '龙岩市',
    Ningde: '宁德市',
    Jiangxi: '江西省',
    Nanchang: '南昌市',
    Jingdezhen: '景德镇市',
    Pingxiang: '萍乡市',
    Jiujiang: '九江市',
    Xinyu: '新余市',
    Yingtan: '鹰潭市',
    Ganzhou: '赣州市',
    Gian: '吉安市',
    Yichun: '宜春市',
    JiangxiFuzhou: '抚州市',
    Shangrao: '上饶市',
    Shandong: '山东省',
    Jinan: '济南市',
    Qingdao: '青岛市',
    Zibo: '淄博市',
    Zaozhuang: '枣庄市',
    Dongying: '东营市',
    Yantai: '烟台市',
    Weifang: '潍坊市',
    Jining: '济宁市',
    Tyan: '泰安市',
    Weihai: '威海市',
    Rizhao: '日照市',
    Linyi: '临沂市',
    Liaocheng: '聊城市',
    Texas: '德州市',
    Binzhou: '滨州市',
    Heze: '菏泽市',
    Henan: '河南省',
    Zhengzhou: '郑州市',
    Kaifeng: '开封市',
    Luoyang: '洛阳市',
    Pingdingshan: '平顶山市',
    Anyang: '安阳市',
    Hebi: '鹤壁市',
    Xinxiang: '新乡市',
    Jiaozuo: '焦作市',
    Puyang: '濮阳市',
    Xuchang: '许昌市',
    Luohe: '漯河市',
    Sanmenxia: '三门峡市',
    Nanyang: '南阳市',
    Shangqiu: '商丘市',
    Xinyang: '信阳市',
    Zhoukou: '周口市',
    Zhumadian: '驻马店市',
    Hubei: '湖北省',
    Wuhan: '武汉市',
    Huangshi: '黄石市',
    Shiyan: '十堰市',
    Yichang: '宜昌市',
    Xiangyang: '襄阳市',
    Ezhou: '鄂州市',
    Jingmen: '荆门市',
    Xiaogan: '孝感市',
    Jingzhou: '荆州市',
    Huanggang: '黄冈市',
    Xianning: '咸宁市',
    Suizhou: '随州市',
    EnshiTujiaMiao: '恩施土家族苗族自治州',
    Hunan: '湖南省',
    Changsha: '长沙市',
    Zhuzhou: '株洲市',
    Xiangtan: '湘潭市',
    Hengyang: '衡阳市',
    Shaoyang: '邵阳市',
    Yueyang: '岳阳市',
    Changde: '常德市',
    Zhangjiajie: '张家界市',
    Yiyang: '益阳市',
    Chenzhou: '郴州市',
    Yongzhou: '永州市',
    Huaihua: '怀化市',
    XiangxiTujiaandMiaoAutonomousPrefecture: '湘西土家族苗族',
    Loudi: '娄底市',
    Guangdong: '广东省',
    Guangzhou: '广州市',
    Shaoguan: '韶关市',
    Shenzhen: '深圳市',
    Zhuhai: '珠海市',
    Shantou: '汕头市',
    Foshan: '佛山市',
    Jiangmen: '江门市',
    Zhanjiang: '湛江市',
    Maoming: '茂名市',
    Zhaoqing: '肇庆市',
    Huizhou: '惠州市',
    Meizhou: '梅州市',
    Shanwei: '汕尾市',
    Heyuan: '河源市',
    Yangjiang: '阳江市',
    Qingyuan: '清远市',
    Dongguan: '东莞市',
    Zhongshan: '中山市',
    Chaozhou: '潮州市',
    Jieyang: '揭阳市',
    Yunfu: '云浮市',
    Guangxi: '广西壮族自治区',
    Nanning: '南宁市',
    Liuzhou: '柳州市',
    Guilin: '桂林市',
    Wuzhou: '梧州市',
    Beihai: '北海市',
    Fangchenggang: '防城港市',
    Qinzhou: '钦州市',
    Guigang: '贵港市',
    GuangxiYulin: '玉林市',
    Baise: '百色市',
    Hezhou: '贺州市',
    Hechi: '河池市',
    Laibin: '来宾市',
    Chongzuo: '崇左市',
    Hainan: '海南省',
    Haikou: '海口市',
    Sanya: '三亚市',
    Sansha: '三沙市',
    Danzhou: '儋州市',
    Chongqing: '重庆市',
    Sichuan: '四川省',
    Chengdu: '成都市',
    Zigong: '自贡市',
    Panzhihua: '攀枝花市',
    Deyang: '德阳市',
    Luzhou: '泸州市',
    Mianyang: '绵阳市',
    Guangyuan: '广元市',
    Suining: '遂宁市',
    Neijiang: '内江市',
    Leshan: '乐山市',
    Nanchong: '南充市',
    Meishan: '眉山市',
    Yibin: '宜宾市',
    Guangan: '广安市',
    Dazhou: '达州市',
    Yaan: '雅安市',
    Bazhong: '巴中市',
    Ziyang: '资阳市',
    AbaTibetanandQiangAutonomousPrefecture: '阿坝藏族羌族自治州',
    GanziTibetanAutonomousPrefecture: '甘孜藏族自治州',
    LiangshanYiAutonomousPrefecture: '凉山彝族自治州',
    Guizhou: '贵州省',
    Guiyang: '贵阳市',
    Liupanshui: '六盘水市',
    Zunyi: '遵义市',
    Anshun: '安顺市',
    Bijie: '毕节市',
    Tongren: '铜仁市',
    MiaoAutonomousPrefecture: '黔西南布依族苗族自治州',
    SoutheastGuizhouMiaoandDongAutonomousPrefecture: '黔东南苗族侗族自治州',
    WeinanBuyiandMiaoAutonomousPrefecture: '黔南布依族苗族自治州',
    Yunnan: '云南省',
    Kunming: '昆明市',
    Qujing: '曲靖市',
    Yuxi: '玉溪市',
    Baoshan: '保山市',
    Zhaotong: '昭通市',
    Lincang: '丽江市',
    Puer: '普洱市',
    LingQang: '临沧市',
    ChuxiongYiAutonomousPrefecture: '楚雄彝族自治州',
    HongheHaniandYiAutonomousPrefecture: '红河哈尼族彝族自治州',
    WenshanZhuangandMiaoAutonomousPrefecture: '文山壮族苗族自治州',
    XishuangbannaDaiAutonomousPrefecture: '西双版纳傣族自治州',
    DaliBaiAutonomousPrefecture: '大理白族自治州',
    DehongDaiJingpoAutonomousPrefecture: '德宏傣族景颇族自治州',
    NujiangYiAutonomousPrefecture: '怒江傈僳族自治州',
    DiqingTibetanAutonomousPrefecture: '迪庆藏族自治州',
    Tibet: '西藏自治区',
    Lhasa: '拉萨市',
    Shigatse: '日喀则市',
    Changdu: '昌都市',
    Linzhi: '林芝市',
    Shannan: '山南市',
    Nagqu: '那曲市',
    Ali: '阿里地区',
    Shaanxi: '陕西省',
    Xian: '西安市',
    Tongchuan: '铜川市',
    Baoji: '宝鸡市',
    Xianyang: '咸阳市',
    Weinan: '渭南市',
    Yanan: '延安市',
    Hanzhong: '汉中市',
    Yulin: '榆林市',
    Ankang: '安康市',
    Shangluo: '商洛市',
    Gansu: '甘肃省',
    Lanzhou: '兰州市',
    Jiayuguan: '嘉峪关市',
    KimChang: '金昌市',
    Baiyin: '白银市',
    Tianshui: '天水市',
    Wuwei: '武威市',
    Zhangye: '张掖市',
    Pingliang: '平凉市',
    Jiuquan: '酒泉市',
    Qingyang: '庆阳市',
    Dingxi: '定西市',
    Longnan: '陇南市',
    Linxia: '临夏回族自治州',
    Gannan: '甘南藏族自治州',
    Qinghai: '青海省',
    Xining: '西宁市',
    Haidong: '海东市',
    Haibei: '海北藏族自治州',
    Huangnan: '黄南藏族自治州',
    HainanTibetanAutonomousPrefecture: '海南藏族自治州',
    Guoluo: '果洛藏族自治州',
    Yushu: '玉树藏族自治州',
    Haixi: '海西蒙古族藏族自治州',
    Ningxia: '宁夏回族自治区',
    Yinchuan: '银川市',
    Shizuishan: '石嘴山市',
    WuZhong: '吴忠市',
    Guyuan: '固原市',
    Zhongwei: '中卫市',
    Xinjiang: '新疆维吾尔自治区',
    Urumqi: '乌鲁木齐市',
    Karamay: '克拉玛依市',
    Turpan: '吐鲁番市',
    Hami: '哈密市',
    Changji: '昌吉回族自治州',
    BortalaMongolianAutonomousPrefecture: '博尔塔拉蒙古自治州',
    BayinGuoyuMongolianAutonomousPrefecture: '巴音郭楞蒙古自治州',
    Akesu: '阿克苏地区',
    KyzlerKirkAutonomousPrefecture: '克孜勒苏柯尔克孜自治州',
    Kashi: '喀什地区',
    Hetian: '和田地区',
    IliKazakhAutonomousPrefecture: '伊犁哈萨克自治州',
    Tachengarea: '塔城地区',
    Altayarea: '阿尔泰地区',
    Hongkong: '香港特别行政区',
    Macao: '澳门特别行政区',
    OtherCity: '其它城市',
    OtherProvince: '其它省份',
  },
  'en-us': {
    Beijing: 'Beijing',
    TianJin: 'TianJin',
    Hebei: 'Hebei',
    Shijiazhuang: 'Shijiazhuang',
    Tangshan: 'Tangshan',
    Qinhuangdao: 'Qinhuangdao',
    Handan: 'Handan',
    Xingtai: 'Xingtai',
    Baoding: 'Baoding',
    Zhangjiakou: 'Zhangjiakou',
    Chengde: 'Chengde',
    Cangzhou: 'Cangzhou',
    Langfang: 'Langfang',
    Hengshui: 'Hengshui',
    Shanxi: 'Shanxi',
    Taiyuan: 'aiyuan',
    Datong: 'Datong',
    Yangquan: 'Yangquan',
    Changzhi: 'Changzhi',
    Jincheng: 'Jincheng',
    Shuozhou: 'Shuozhou',
    Jinzhong: 'Jinzhong',
    Yuncheng: 'Yuncheng',
    Xinzhou: 'Xinzhou',
    Linfen: 'infen',
    Luliang: 'Luliang',
    InnerMongoliai: 'InnerMongoliai',
    Hohhot: 'Hohhot',
    Baotou: 'Baotou',
    Wuhai: 'Wuhai',
    Chifeng: 'Chifeng',
    Tongliao: 'Tongliao',
    Erdos: 'Erdos',
    Hulunbeir: 'Hulunbeir',
    Bayannaoer: 'Bayannaoer',
    Wulanchabu: 'Wulanchabu',
    Hinggan: 'Hinggan',
    Xilingol: 'Xilingol',
    Alxa: 'Alxa',
    Liaoning: 'Liaoning',
    Shenyang: 'Shenyang',
    Dairen: 'Dairen',
    Anshan: 'Anshan',
    Fushun: 'Fushun',
    Benxi: 'Benxi',
    Dandong: 'Dandong',
    Jinzhou: 'Jinzhou',
    Yingkou: 'Yingkou',
    Fuxin: 'Fuxin',
    Liaoyang: 'Liaoyang',
    Panjin: 'Panjin',
    Tieling: 'Tieling',
    Chaoyang: 'Chaoyang',
    Huludao: 'Huludao',
    JilinProvince: 'Jilin',
    Changchun: 'Changchun',
    Jilin: 'Jilin',
    Siping: 'Siping',
    Liaoyuan: 'Liaoyuan',
    Tonghua: 'Tonghua',
    Baishan: 'Baishan',
    Songyuan: 'Songyuan',
    Baicheng: 'Baicheng',
    YanbianKorean: 'Yanbian Korean Autonomous Prefecture',
    Heilongjiang: 'Heilongjiang',
    Harbin: 'Harbin',
    Qiqihar: 'Qiqihar',
    Jixi: 'Jixi',
    Hegang: 'Hegang',
    Shuangyashan: 'Shuangyashan',
    Daqing: 'Daqing',
    Qitaihe: 'Qitaihe',
    Jiamusi: 'Jiamusi',
    HeilongjiangQitaihe: 'Qitaihe',
    Mudanjiang: 'Mudanjiang',
    Heihe: 'Heihe',
    Suihua: 'Suihua',
    Daxinganling: 'Daxinganling Prefecture',
    Shanghai: 'Shanghai',
    Jiangsu: 'Jiangsu',
    Nanjing: 'Nanjing',
    Wuxi: 'Wuxi',
    Xuzhou: 'Xuzhou',
    Changzhou: 'Changzhou',
    Suzhou: 'Suzhou',
    Nantong: 'Nantong',
    Lianyungang: 'Lianyungang',
    Huaian: 'Huaian',
    Yancheng: 'Yancheng',
    Yangzhou: 'Yangzhou',
    Zhenjiang: 'Zhenjiang',
    JiangsuTaizhou: 'Taizhou',
    Suqian: 'Suqian',
    Zhejiang: 'Zhejiang',
    Hangzhou: 'Hangzhou',
    Ningbo: 'Ningbo',
    Wenzhou: 'Wenzhou',
    Jiaxing: 'Jiaxing',
    Huzhou: 'Huzhou',
    Shaoxing: 'Shaoxing',
    Jinhua: 'Jinhua',
    Quzhou: 'Quzhou',
    Zhoushan: 'Zhoushan',
    Taizhou: 'Taizhou',
    Lishui: 'Lishui',
    Anhui: 'Anhui',
    Hefei: 'Hefei',
    Wuhu: 'Wuhu',
    Bengbu: 'Bengbu',
    Huainan: 'Huainan',
    Maanshan: 'Maanshan',
    Huaibei: 'Huaibei',
    Tongling: 'Tongling',
    Anqing: 'Anqing',
    Huangshan: 'Huangshan',
    Chuzhou: 'Chuzhou',
    Fuyang: 'Fuyang',
    AnhuiSuzhou: 'Suzhou',
    Luan: 'Luan',
    Bozhou: 'Bozhou',
    Chizhou: 'Chizhou',
    Xuancheng: 'Xuancheng',
    Fujian: 'Fujian',
    Fuzhou: 'Fuzhou',
    Xiamen: 'Xiamen',
    Putian: 'Putian',
    Sanming: 'Sanming',
    Quanzhou: 'Quanzhou',
    Zhangzhou: 'Zhangzhou',
    Nanping: 'Nanping',
    Longyan: 'Longyan',
    Ningde: 'Ningde',
    Jiangxi: 'Jiangxi',
    Nanchang: 'Nanchang',
    Jingdezhen: 'Jingdezhen',
    Pingxiang: 'Pingxiang',
    Jiujiang: 'Jiujiang',
    Xinyu: 'Xinyu',
    Yingtan: 'Yingtan',
    Ganzhou: 'Ganzhou',
    Gian: 'Gian',
    Yichun: 'Yichun',
    JiangxiFuzhou: 'Fuzhou',
    Shangrao: 'Shangrao',
    Shandong: 'Shandong',
    Jinan: 'Jinan',
    Qingdao: 'Qingdao',
    Zibo: 'Zibo',
    Zaozhuang: 'Zaozhuang',
    Dongying: 'Dongying',
    Yantai: 'Yantai',
    Weifang: 'Weifang',
    Jining: 'Jining',
    Tyan: 'Tyan',
    Weihai: 'Weihai',
    Rizhao: 'Rizhao',
    Linyi: 'Linyi',
    Liaocheng: 'Liaocheng',
    Texas: 'Texas',
    Binzhou: 'Binzhou',
    Heze: 'Heze',
    Henan: 'Henan',
    Zhengzhou: 'Zhengzhou',
    Kaifeng: 'Kaifeng',
    Luoyang: 'Luoyang',
    Pingdingshan: 'Pingdingshan',
    Anyang: 'Anyang',
    Hebi: 'Hebi',
    Xinxiang: 'Xinxiang',
    Jiaozuo: 'Jiaozuo',
    Puyang: 'Puyang',
    Xuchang: 'Xuchang',
    Luohe: 'Luohe',
    Sanmenxia: 'Sanmenxia',
    Nanyang: 'Nanyang',
    Shangqiu: 'Shangqiu',
    Xinyang: 'Xinyang',
    Zhoukou: 'Zhoukou',
    Zhumadian: 'Zhumadian',
    Hubei: 'Hubei',
    Wuhan: 'Wuhan',
    Huangshi: 'Huangshi',
    Shiyan: 'Shiyan',
    Yichang: 'Yichang',
    Xiangyang: 'Xiangyang',
    Ezhou: 'Ezhou',
    Jingmen: 'Jingmen',
    Xiaogan: 'Xiaogan',
    Jingzhou: 'Jingzhou',
    Huanggang: 'Huanggang',
    Xianning: 'Xianning',
    Suizhou: 'Suizhou',
    EnshiTujiaMiao: 'Enshi Tujia and Miao Autonomous Prefecture',
    Hunan: 'Hunan',
    Changsha: 'Changsha',
    Zhuzhou: 'Zhuzhou',
    Xiangtan: 'Xiangtan',
    Hengyang: 'Hengyang',
    Shaoyang: 'Shaoyang',
    Yueyang: 'Yueyang',
    Changde: 'Changde',
    Zhangjiajie: 'Zhangjiajie',
    Yiyang: 'Yiyang',
    Chenzhou: 'Chenzhou',
    Yongzhou: 'Yongzhou',
    Huaihua: 'Huaihua',
    XiangxiTujiaandMiaoAutonomousPrefecture: 'XiangxiTujiaandMiaoAutonomousPrefecture',
    Loudi: 'Loudi',
    Guangdong: 'Guangdong',
    Guangzhou: 'Guangzhou',
    Shaoguan: 'Shaoguan',
    Shenzhen: 'Shenzhen',
    Zhuhai: 'Zhuhai',
    Shantou: 'Shantou',
    Foshan: 'Foshan',
    Jiangmen: 'Jiangmen',
    Zhanjiang: 'Zhanjiang',
    Maoming: 'Maoming',
    Zhaoqing: 'Zhaoqing',
    Huizhou: 'Huizhou',
    Meizhou: 'Meizhou',
    Shanwei: 'Shanwei',
    Heyuan: 'Heyuan',
    Yangjiang: 'Yangjiang',
    Qingyuan: 'Qingyuan',
    Dongguan: 'Dongguan',
    Zhongshan: 'Zhongshan',
    Chaozhou: 'Chaozhou',
    Jieyang: 'Jieyang',
    Yunfu: 'Yunfu',
    Guangxi: 'Guangxi',
    Nanning: 'Nanning',
    Liuzhou: 'Liuzhou',
    Guilin: 'Guilin',
    Wuzhou: 'Wuzhou',
    Beihai: 'Beihai',
    Fangchenggang: 'Fangchenggang',
    Qinzhou: 'Qinzhou',
    Guigang: 'Guigang',
    GuangxiYulin: 'Yulin',
    Baise: 'Baise',
    Hezhou: 'Hezhou',
    Hechi: 'Hechi',
    Laibin: 'Laibin',
    Chongzuo: 'Chongzuo',
    Hainan: 'Hainan',
    Haikou: 'Haikou',
    Sanya: 'Sanya',
    Sansha: 'Sansha',
    Danzhou: 'Danzhou',
    Chongqing: 'Chongqing',
    Sichuan: 'Sichuan',
    Chengdu: 'Chengdu',
    Zigong: 'Zigong',
    Panzhihua: 'Panzhihua',
    Deyang: 'Deyang',
    Luzhou: 'Luzhou',
    Mianyang: 'Mianyang',
    Guangyuan: 'Guangyuan',
    Suining: 'Suining',
    Neijiang: 'Neijiang',
    Leshan: 'Leshan',
    Nanchong: 'Nanchong',
    Meishan: 'Meishan',
    Yibin: 'Yibin',
    Guangan: 'Guang’an',
    Dazhou: 'Dazhou',
    Yaan: 'Yaan',
    Bazhong: 'Bazhong',
    Ziyang: 'Ziyang',
    AbaTibetanandQiangAutonomousPrefecture: 'AbaTibetanandQiangAutonomousPrefecture',
    GanziTibetanAutonomousPrefecture: 'GanziTibetanAutonomousPrefecture',
    LiangshanYiAutonomousPrefecture: 'LiangshanYiAutonomousPrefecture',
    Guizhou: 'Guizhou',
    Guiyang: 'Guiyang',
    Liupanshui: 'Liupanshui',
    Zunyi: 'Zunyi',
    Anshun: 'Anshun',
    Bijie: 'Bijie',
    Tongren: 'Tongren',
    MiaoAutonomousPrefecture: 'MiaoAutonomousPrefecture',
    SoutheastGuizhouMiaoandDongAutonomousPrefecture: 'SoutheastGuizhouMiaoandDongAutonomousPrefecture',
    WeinanBuyiandMiaoAutonomousPrefecture: 'WeinanBuyiandMiaoAutonomousPrefecture',
    Yunnan: 'Yunnan',
    Kunming: 'Kunming',
    Qujing: 'Qujing',
    Yuxi: 'Yuxi',
    Baoshan: 'Baoshan',
    Zhaotong: 'Zhaotong',
    Lincang: 'Lincang',
    Puer: 'Puer',
    LingQang: 'LingQang',
    ChuxiongYiAutonomousPrefecture: 'ChuxiongYiAutonomousPrefecture',
    HongheHaniandYiAutonomousPrefecture: 'HongheHaniandYiAutonomousPrefecture',
    WenshanZhuangandMiaoAutonomousPrefecture: 'WenshanZhuangandMiaoAutonomousPrefecture',
    XishuangbannaDaiAutonomousPrefecture: 'XishuangbannaDaiAutonomousPrefecture',
    DaliBaiAutonomousPrefecture: 'DaliBaiAutonomousPrefecture',
    DehongDaiJingpoAutonomousPrefecture: 'DehongDaiJingpoAutonomousPrefecture',
    NujiangYiAutonomousPrefecture: 'NujiangYiAutonomousPrefecture',
    DiqingTibetanAutonomousPrefecture: 'DiqingTibetanAutonomousPrefecture',
    Tibet: 'Tibet',
    Lhasa: 'Lhasa',
    Shigatse: 'Shigatse',
    Changdu: 'Changdu',
    Linzhi: 'Linzhi',
    Shannan: 'Shannan',
    Nagqu: 'Nagqu',
    Ali: 'Ali',
    Shaanxi: 'Shaanxi',
    Xian: 'Xi’an',
    Tongchuan: 'Tongchuan',
    Baoji: 'Baoji',
    Xianyang: 'Xianyang',
    Weinan: 'Weinan',
    Yanan: 'Yan’an',
    Hanzhong: 'Hanzhong',
    Yulin: 'Yulin',
    Ankang: 'Ankang',
    Shangluo: 'Shangluo',
    Gansu: 'Gansu',
    Lanzhou: 'Lanzhou',
    Jiayuguan: 'Jiayuguan',
    KimChang: 'KimChang',
    Baiyin: 'Baiyin',
    Tianshui: 'Tianshui',
    Wuwei: 'Wuwei',
    Zhangye: 'Zhangye',
    Pingliang: 'Pingliang',
    Jiuquan: 'Jiuquan',
    Qingyang: 'Qingyang',
    Dingxi: 'Dingxi',
    Longnan: 'Longnan',
    Linxia: 'Linxia',
    Gannan: 'Gannan',
    Qinghai: 'Qinghai',
    Xining: 'Xining',
    Haidong: 'Haidong',
    Haibei: 'Haibei',
    Huangnan: 'Huangnan Tibetan',
    HainanTibetanAutonomousPrefecture: 'HainanTibetanAutonomousPrefecture',
    Guoluo: 'Guoluo',
    Yushu: 'Yushu',
    Haixi: 'Haixi',
    Ningxia: 'Ningxia',
    Yinchuan: 'Yinchuan',
    Shizuishan: 'Shizuishan',
    WuZhong: 'WuZhong',
    Guyuan: 'Guyuan',
    Zhongwei: 'Zhongwei',
    Xinjiang: 'Xinjiang',
    Urumqi: 'Urumqi',
    Karamay: 'Karamay',
    Turpan: 'Turpan',
    Hami: 'Hami',
    Changji: 'Changji',
    BortalaMongolianAutonomousPrefecture: 'Bortala Mongolian Autonomous Prefecture',
    BayinGuoyuMongolianAutonomousPrefecture: 'Bayin Guoyu Mongolian Autonomous Prefecture',
    Akesu: 'Akesu',
    KyzlerKirkAutonomousPrefecture: 'Kyzler Kirk Autonomous Prefecture',
    Kashi: 'Kashi',
    Hetian: 'Hetian',
    IliKazakhAutonomousPrefecture: 'IliKazakhAutonomousPrefecture',
    Tachengarea: 'Tachengarea',
    Altayarea: 'Altayarea',
    Hongkong: 'Hongkong',
    Macao: 'Macao',
    OtherCity: 'Other City',
    OtherProvince: 'Other Province',
  },
};
