export default {
  'zh-tw': {
    controllerUpgradeWinCeSys: '控制器升級WinCE系統',
    x86SoftwareVersionUpperLimitUnlock: 'x86軟體版本上限解鎖',
    enablePluginFunction: '啟用外掛Plugln功能',
    enableFrontAndBack: '啟用前後台應用',
    hpcc: '高精軌跡控制模式(HPCC)',
    rtcp: '刀尖點控制功能(RTCP)/自動化專用RTCP功能',
    fc: '特徵座標系功能(FC)',
    paramLearnFunction: '參數學習功能',
    learnTappingFunction: '學習攻牙功能',
    singleCamera: '單相機 -> 新代視覺',
    multiCamera: '多相機 -> 市售視覺',
    cadOrCam: 'CAD/CAM功能',
    threeDSpaceArcFunction: '三維空間圓弧插補功能',
    allInOneRightAngle: 'All in one直角取放',
    fourJointRobot: '四關節機器人',
    fiveJointRobot: '五關節機器人',
    sixJointRobot: '六關節機器人',
    cartesianCoordinateRobot: '直角座標型機器人型態',
    highSpeedPreSolution: '高速預解功能',
    smoothToolTipPointControl: '平滑刀尖點控制功能',
    multiGroupFiveAxis: '多組五軸機構鏈',
    fourAXRTCP: '四軸專用刀尖點控制功能(4AXRTCP)',
    fiveAxisLinkage: '五軸聯動功能',
    highlightTrackControl: '高光軌跡控制',
    rotServoTurret: 'ROT伺服刀塔',
    woodworkingSoftwareFunction: '木工軟體功能',
    woodworkingBuiltInCamFunction: '木工Built-In CAM功能',
    woodworkingAutomaticLabeling: '木工自動貼標',
    rightAngleMultiArmFunction: '直角多手臂功能',
    twoDimensionalSimultaneousMarking: '二維同動打標',
    expandTheNumberOfT: '擴充T碼數量至最大上限',
    oneDimensionalSimultaneousMarking: '一維同動打標',
    woodworkingLabelingAndCuttingPcSoftware: '木工貼標開料PC軟體',
    choppingFunction: 'Chopping 功能',
    notEnabled_1: '1.未啟用',
    dosUpgradeToWinCE_2: '2.控制器升級WinCE系統',
    x86SoftwareLimitUnlock_3: '3.x86軟體版本上限解鎖',
    enablePluginFunction_4: '4.啟用外掛PlugIn功能',
    enableFrontAndBack_5: '5.啟用前後台應用',
    notEnabled_6: '6.未啟用',
    notEnabled_7: '7.未啟用',
    notEnabled_8: '8.未啟用',
    notEnabled_9: '9.未啟用',
    notEnabled_10: '10.未啟用',
    highPrecisionContourControlHPCC_11: '11.高精軌跡控制模式(HPCC)',
    rotationToolCenterPointRTCPautomationRTCP_12: '12.刀尖點控制功能(RTCP)/自動化專用RTCP功能 ',
    featureCoordinate_13: '13.特徵座標系功能(FC)',
    parameterLearning_14: '14.參數學習功能',
    tappingLearning_15: '15.學習攻牙功能',
    syntecVision_16: '16.單相機 -> 新代視覺',
    commercialVisionSoftware_17: '17.多相機 -> 市售視覺',
    CADCAM_18: '18.CAD/CAM功能',
    ArcInterpolation3DA_19: '19.三維空間圓弧插補功能',
    PickAndPlaceAllInOne_20: '20.All in one直角取放',
    fourAxisRobot_21: '21.四關節機器人',
    fiveAxisRobot_22: '22.五關節機器人',
    sixAxisRobot_23: '23.六關節機器人',
    GantryRobot_24: '24.直角座標型機器人型態',
    advancedLookAhead_25: '25.高速預解功能',
    smoothToolCenterPointTCP_26: '26.平滑刀尖點控制功能',
    multipleMechanismsForFiveaxis_27: '27.多組五軸機構鏈',
    the2ndCoordinateSupportsFiveaxisFunctions_28: '28.未啟用',
    fourAxisRotationToolCenterPoint_29: '29.四軸專用刀尖點控制功能(4AXRTCP)',
    fiveAxisSynchronizedMotionControl_30: '30.五軸聯動功能',
    HighSpeedChamferingContourControl_31: '31.高光軌跡控制',
    ROTServoTurret_32: '32.ROT伺服刀塔',
    advancedLookAheadForTurnMill_33: '33.未啟用',
    woodSoftware_34: '34.木工軟體功能',
    notEnabled_35: '35.未啟用',
    woodBuiltInCAM_36: '36.木工Built-In CAM功能',
    woodAutoLabeling_37: '37.木工自動貼標',
    multiGantryRobot_38: '38.直角多手臂功能',
    synchronizedMarking_39: '39.二維同動打標',
    expandTcodeFunctionToMaxAmount_40: '40.擴充T碼數量至最大上限',
    dynamicBalancingForGrinder_41: '41.磨床動平衡功能',
    oneDimensionalSynchronizedMarking_42: '42.一維同動打標',
    woodLabelingRouterPCSoftware_43: '43.木工貼標開料PC軟體',
    chopping_44: '44.Chopping 功能',
    notEnabled_45: '45.未啟用',
    intelligentCheck5_46: '46.智慧五軸量測功能',
    notEnabled_47: '47.未啟用',
    notEnabled_48: '48.未啟用',
    smartThermalCompensation_49: '49.智慧溫升補償',
  },
  'zh-cn': {
    controllerUpgradeWinCeSys: '控制器升级WinCE系统',
    x86SoftwareVersionUpperLimitUnlock: 'x86软体版本上限解锁',
    enablePluginFunction: '启用外挂Plugln功能',
    enableFrontAndBack: '启用前后台应用',
    hpcc: '高精轨迹控制模式(HPCC)',
    rtcp: '刀尖点控制功能(RTCP)/自动化专用RTCP功能',
    fc: '特征座标系功能(FC)',
    paramLearnFunction: '参数学习功能',
    learnTappingFunction: '学习攻牙功能',
    singleCamera: '单相机 -> 新代视觉',
    multiCamera: '多相机 -> 市售视觉',
    cadOrCam: 'CAD/CAM功能',
    threeDSpaceArcFunction: '三维空间圆弧插补功能',
    allInOneRightAngle: 'All in one直角取放',
    fourJointRobot: '四关节机器人',
    fiveJointRobot: '五关节机器人',
    sixJointRobot: '六关节机器人',
    cartesianCoordinateRobot: '直角座标型机器人型态',
    highSpeedPreSolution: '高速预解功能',
    smoothToolTipPointControl: '平滑刀尖点控制功能',
    multiGroupFiveAxis: '多组五轴机构链',
    fourAXRTCP: '四轴专用刀尖点控制功能(4AXRTCP)',
    fiveAxisLinkage: '五轴联动功能',
    highlightTrackControl: '高光轨迹控制',
    rotServoTurret: 'ROT伺服刀塔',
    woodworkingSoftwareFunction: '木工软体功能',
    woodworkingBuiltInCamFunction: '木工Built-In CAM功能',
    woodworkingAutomaticLabeling: '木工自动贴标',
    rightAngleMultiArmFunction: '直角多手臂功能',
    twoDimensionalSimultaneousMarking: '二维同动打标',
    expandTheNumberOfT: '扩充T码数量至最大上限',
    oneDimensionalSimultaneousMarking: '一维同动打标',
    woodworkingLabelingAndCuttingPcSoftware: '木工贴标开料PC软体',
    choppingFunction: 'Chopping 功能',
    notEnabled_1: '1.未启用',
    dosUpgradeToWinCE_2: '2.控制器升级WinCE系统',
    x86SoftwareLimitUnlock_3: '3.x86软体版本上限解锁',
    enablePluginFunction_4: '4.启用外挂PlugIn功能',
    enableFrontAndBack_5: '5.启用前后台应用',
    notEnabled_6: '6.未启用',
    notEnabled_7: '7.未启用',
    notEnabled_8: '8.未启用',
    notEnabled_9: '9.未启用',
    notEnabled_10: '10.未启用',
    highPrecisionContourControlHPCC_11: '11.高精轨迹控制模式(HPCC)',
    rotationToolCenterPointRTCPautomationRTCP_12: '12.刀尖点控制功能(RTCP)/自动化专用RTCP功能 ',
    featureCoordinate_13: '13.特徵座标系功能(FC)',
    parameterLearning_14: '14.参数学习功能',
    tappingLearning_15: '15.学习攻牙功能',
    syntecVision_16: '16.单相机 -> 新代视觉',
    commercialVisionSoftware_17: '17.多相机 -> 市售视觉',
    CADCAM_18: '18.CAD/CAM功能',
    ArcInterpolation3DA_19: '19.三维空间圆弧插补功能',
    PickAndPlaceAllInOne_20: '20.All in one直角取放',
    fourAxisRobot_21: '21.四关节机器人',
    fiveAxisRobot_22: '22.五关节机器人',
    sixAxisRobot_23: '23.六关节机器人',
    GantryRobot_24: '24.直角座标型机器人型态',
    advancedLookAhead_25: '25.高速预解功能',
    smoothToolCenterPointTCP_26: '26.平滑刀尖点控制功能',
    multipleMechanismsForFiveaxis_27: '27.多组五轴机构链',
    the2ndCoordinateSupportsFiveaxisFunctions_28: '28.未启用',
    fourAxisRotationToolCenterPoint_29: '29.四轴专用刀尖点控制功能(4AXRTCP)',
    fiveAxisSynchronizedMotionControl_30: '30.五轴联动功能',
    HighSpeedChamferingContourControl_31: '31.高光轨迹控制',
    ROTServoTurret_32: '32.ROT伺服刀塔',
    advancedLookAheadForTurnMill_33: '33.未启用',
    woodSoftware_34: '34.木工软体功能',
    notEnabled_35: '35.未启用',
    woodBuiltInCAM_36: '36.木工Built-In CAM功能',
    woodAutoLabeling_37: '37.木工自动贴标',
    multiGantryRobot_38: '38.直角多手臂功能',
    synchronizedMarking_39: '39.二维同动打标',
    expandTcodeFunctionToMaxAmount_40: '40.扩充T码数量至最大上限',
    dynamicBalancingForGrinder_41: '41.磨床动平衡功能',
    oneDimensionalSynchronizedMarking_42: '42.一维同动打标',
    woodLabelingRouterPCSoftware_43: '43.木工贴标开料PC软体',
    chopping_44: '44.Chopping 功能',
    notEnabled_45: '45.未启用',
    intelligentCheck5_46: '46.智慧五轴量测功能',
    notEnabled_47: '47.未启用',
    notEnabled_48: '48.未启用',
    smartThermalCompensation_49: '49.智慧温升补偿',
  },
  'en-us': {
    controllerUpgradeWinCeSys: 'Controller upgrade WinCE system',
    x86SoftwareVersionUpperLimitUnlock: 'x86 software version upper limit unlock',
    enablePluginFunction: 'Enable plug-in function',
    enableFrontAndBack: 'Enable front and back application',
    hpcc: 'High-precision trajectory control mode (HPCC)',
    rtcp: 'Tool tip point control function (RTCP)/automation dedicated RTCP function',
    fc: 'Feature coordinate system function (FC)',
    paramLearnFunction: 'Parameter learning function',
    learnTappingFunction: 'Learn tapping function',
    singleCamera: 'Single Camera -> New Generation Vision',
    multiCamera: 'Multi-camera -> Commercial Vision',
    cadOrCam: 'CAD/CAM function',
    threeDSpaceArcFunction: 'Three-dimensional space arc interpolation function',
    allInOneRightAngle: 'All in one right-angle pick and place',
    fourJointRobot: 'Four-joint robot',
    fiveJointRobot: 'Five-joint robot',
    sixJointRobot: 'Six-joint robot',
    cartesianCoordinateRobot: 'Cartesian coordinate robot type',
    highSpeedPreSolution: 'High-speed pre-solution function',
    smoothToolTipPointControl: 'Smooth tool tip point control function',
    multiGroupFiveAxis: 'Multi-group five-axis mechanism chain',
    fourAXRTCP: 'Four-axis special tool tip point control function (4AXRTCP)',
    fiveAxisLinkage: 'Five-axis linkage function',
    highlightTrackControl: 'Highlight Track Control',
    rotServoTurret: 'ROT Servo Turret',
    woodworkingSoftwareFunction: 'Woodworking software function',
    woodworkingBuiltInCamFunction: 'Woodworking Built-In CAM Function',
    woodworkingAutomaticLabeling: 'Woodworking automatic labeling',
    rightAngleMultiArmFunction: 'Right-angle multi-arm function',
    twoDimensionalSimultaneousMarking: 'Two-dimensional simultaneous marking',
    expandTheNumberOfT: 'Expand the number of T codes to the maximum limit',
    oneDimensionalSimultaneousMarking: 'One-dimensional simultaneous marking',
    woodworkingLabelingAndCuttingPcSoftware: 'Woodworking labeling and cutting PC software',
    choppingFunction: 'Chopping function',
    notEnabled_1: '1.Not Enabled',
    dosUpgradeToWinCE_2: '2.Dos Upgrade to WinCE',
    x86SoftwareLimitUnlock_3: '3.x86 Software Limit Unlock',
    enablePluginFunction_4: '4.Enable Plugin Function',
    enableFrontAndBack_5: '5.Enable Front And Back Application',
    notEnabled_6: '6.Not Enabled',
    notEnabled_7: '7.Not Enabled',
    notEnabled_8: '8.Not Enabled',
    notEnabled_9: '9.Not Enabled',
    notEnabled_10: '10.Not Enabled',
    highPrecisionContourControlHPCC_11: '11.High Precision Contour Control(HPCC)',
    rotationToolCenterPointRTCPautomationRTCP_12: '12.Rotation Tool Center Point(RTCP)/Automation RTCP',
    featureCoordinate_13: '13.Feature Coordinate',
    parameterLearning_14: '14.Parameter Learning',
    tappingLearning_15: '15.Tapping Learning',
    syntecVision_16: '16.Syntec Vision',
    commercialVisionSoftware_17: 'Commercial Vision Software',
    CADCAM_18: '18.CAD/CAM',
    ArcInterpolation3DA_19: '19.3D Arc Interpolation',
    PickAndPlaceAllInOne_20: '20.Pick and Place (All in one)',
    fourAxisRobot_21: '21.Four Axis Robot',
    fiveAxisRobot_22: '22.Five Axis Robot',
    sixAxisRobot_23: '23.Six Axis Robot',
    GantryRobot_24: '24.Gantry Robot',
    advancedLookAhead_25: '25.Advanced Look-Ahead',
    smoothToolCenterPointTCP_26: '26.Smooth Tool Center Point(TCP)',
    multipleMechanismsForFiveaxis_27: '27.Multiple mechanisms for five-axis',
    the2ndCoordinateSupportsFiveaxisFunctions_28: '28.Not Enabled',
    fourAxisRotationToolCenterPoint_29: '29.Four Axis Rotation Tool Center Point',
    fiveAxisSynchronizedMotionControl_30: '30.Five-Axis Synchronized Motion Control',
    HighSpeedChamferingContourControl_31: '31.High Speed Chamfering Contour Control',
    ROTServoTurret_32: '32.ROT Servo Turret',
    advancedLookAheadForTurnMill_33: '33.Not Enabled',
    woodSoftware_34: '34.Wood Software',
    notEnabled_35: '35.Not Enabled',
    woodBuiltInCAM_36: '36.Wood Built-In CAM',
    woodAutoLabeling_37: '37.Wood Auto Labeling',
    multiGantryRobot_38: '38.Multi-Gantry Robot',
    synchronizedMarking_39: '39.Synchronized Marking',
    expandTcodeFunctionToMaxAmount_40: '40.Expand T-code Function to Max Amount',
    dynamicBalancingForGrinder_41: '41.Dynamic Balancing for Grinder',
    oneDimensionalSynchronizedMarking_42: '42.One-Dimensional Synchronized Marking',
    woodLabelingRouterPCSoftware_43: '43.Wood Labeling/Router PC software',
    chopping_44: '44.Chopping',
    notEnabled_45: '45.Not Enabled',
    intelligentCheck5_46: '46.Intelligent Check - 5',
    notEnabled_47: '47.Not Enabled',
    notEnabled_48: '48.Not Enabled',
    smartThermalCompensation_49: '49.Smart Thermal Compensation',
  },
  /* 翻譯包轉換
  optionsListMapping(optionsList) {
    const map = Object.entries(optionsTranslate['zh-tw']);
    for (let i = 0; i < optionsList.length; i += 1) {
      map.forEach((element) => {
        if (optionsList[i].optionName === element[1]) {
          optionsList[i].optionName = this.$t(element[0]);
        }
      });
    }
  },
  */
};
